import { Button, Typography } from "antd";
import { Booking } from "interfaces/booking";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import welcome from "assets/images/welcome.png";
import { useDispatch } from "react-redux";
import { setBackgroundColor } from "redux-context/page";

// enum Page {
//   Welcome = "Welcome",
//   Certificate = "Certificate",
// }

const Welcome: React.FC = () => {
  // const [page, setPage] = useState(Page.Welcome);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { state } = location || {};
  const { booking }: { booking: Booking } = state || {};

  const onCLickShowCertificate = () => {
    navigate("/certificate", { state: { booking } });
  };

  const showLater = () => {
    navigate("/");
  };

  useEffect(() => {
    dispatch(setBackgroundColor("#e2f2dc"));
    return () => {
      dispatch(setBackgroundColor());
    };
  }, [dispatch]);

  return (
    <div className="page-full-screen fixed green">
      {/* {page === Page.Welcome && ( */}
      <>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
            gap: 60,
          }}
        >
          <div>
            <Typography.Title level={3}>Welcome!</Typography.Title>
            <Typography.Text>
              Restaurant was notified of your arrival. Please present your food
              voucher to the staff.
            </Typography.Text>
          </div>
          <img src={welcome} alt="" style={{ width: 220 }} />
        </div>
        <div>
          <Button
            type="primary"
            size="large"
            shape="round"
            onClick={onCLickShowCertificate}
            style={{ marginTop: 24, width: "100%" }}
          >
            Show voucher
          </Button>
          <Button
            type="link"
            style={{ marginTop: 12, width: "100%", fontWeight: "bold" }}
            onClick={showLater}
          >
            Show later
          </Button>
        </div>
      </>
      {/* )} */}
    </div>
  );
};

export default Welcome;
