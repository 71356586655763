import { LeftOutlined } from "@ant-design/icons";
import { Button, Col, Input, Row, Select, Typography } from "antd";
import { useCallback, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getPlacesByName } from "services/SauceService";
import { debounce } from "lodash";
import "./AddressDelivery.scss";

interface Props {
  // Define your component's props here
}

const AddressDelivery: React.FC<Props> = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;
  const [loadingLocation, setLoadingLocation] = useState<boolean>(false);
  const [places, setPlaces] = useState<any[]>([]);
  const [address, setAddress] = useState<string>();
  const [complement, setComplement] = useState<string>("");

  const getLocation = useCallback(async (locationName: string) => {
    setLoadingLocation(true);
    const response: any = await getPlacesByName({ name: locationName });
    setPlaces(
      response.map((place: any) => ({
        value: place.place_id,
        label: place.name,
        ...place,
      }))
    );
    setLoadingLocation(false);
  }, []);

  const handleSearch = (newValue: string) => {
    if (newValue) getLocation(newValue);
  };

  const handleChange = (_: string, option: any) => {
    setAddress(option.formatted_address);
    // setComplement(option.formatted_address);

    // form.setFieldValue("addressDisplay", option.formatted_address);
    // form.setFieldValue("name", option.name);
  };

  const onClickSave = async () => {
    navigate("/book/collab-confirmation", {
      state: { ...state },
    });
  };

  return (
    <div className="page-full-screen fixed address-deliver">
      <div>
        <Row style={{ alignItems: "center", marginTop: 20 }}>
          <LeftOutlined
            style={{ cursor: "pointer" }}
            onClick={() => navigate(-1)}
          />
          <Typography.Title level={3} style={{ margin: "auto 20px" }}>
            Address for food delivery
          </Typography.Title>
        </Row>
        <div style={{ textAlign: "center", marginTop: 16 }}>
          <Row>
            <Col span={24}>
              <Select
                value={address}
                style={{ width: "100%" }}
                placeholder="Street, address, city"
                showSearch
                suffixIcon={null}
                filterOption={false}
                onSearch={debounce(handleSearch, 1000)}
                onSelect={handleChange}
                options={places}
                loading={loadingLocation}
                allowClear
                optionRender={(option: any) => {
                  return (
                    <>
                      <div style={{ padding: "8px", cursor: "pointer" }}>
                        {`${option.data.name} - ${option.data.formatted_address}`}
                      </div>
                    </>
                  );
                }}
              />
            </Col>
            <Col span={24}>
              <Input
                placeholder="Apartment, suite, unit, etc "
                style={{ marginTop: 20 }}
                value={complement}
                onChange={(e) => setComplement(e.target.value)}
              />
            </Col>
          </Row>
        </div>
      </div>
      <Button type="primary" onClick={onClickSave}>
        Next
      </Button>
    </div>
  );
};

export default AddressDelivery;
