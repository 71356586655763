import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import { ReactComponent as LogoutIcon } from "assets/images/logout-icon.svg";
import { logout } from "helpers/auth-functions";

const LoadingFullScreen: React.FC = () => {
  const onLogoutClick = () => {
    logout();
  };

  return (
    <div
      style={{
        height: "100dvh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          position: "absolute",
          top: 20,
          right: 20,
          cursor: "pointer",
          display: "flex",
          alignItems: "center",
        }}
      >
        <LogoutIcon
          style={{ width: 20, height: 20 }}
          onClick={() => onLogoutClick()}
        />
      </div>
      <Spin indicator={<LoadingOutlined style={{ fontSize: 72 }} spin />} />
    </div>
  );
};

export default LoadingFullScreen;
