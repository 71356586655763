import { createSlice } from "@reduxjs/toolkit";

export const pageSlice: any = createSlice({
  name: "page",
  initialState: {
    backgroundColor: "",
  },
  reducers: {
    setBackgroundColor: (state, action) => {
      state.backgroundColor = action.payload;
    },
    clearBackgroundColor: (state) => {
      state.backgroundColor = "";
    },
  },
  extraReducers: (builder) => {},
});

export const { setBackgroundColor, clearBackgroundColor } = pageSlice.actions;

export default pageSlice.reducer;
