import { Button } from "antd";
import { ReactComponent as InstagramIcon } from "assets/images/instagram-icon.svg";

const InstagramButton: React.FC<{ asLabel?: boolean }> = (props) => {
  const { asLabel } = props;
  const handleLogin = () => {
    // Replace with your Instagram login URL
    const instagramLoginUrl =
      // "https://www.instagram.com/oauth/authorize?enable_fb_login=0&force_authentication=1&client_id=1525568844743129&redirect_uri=https://localhost:3000/instagram-auth&response_type=code&scope=instagram_business_basic%2Cinstagram_business_manage_messages%2Cinstagram_business_manage_comments%2Cinstagram_business_content_publish";
      "https://www.instagram.com/oauth/authorize?enable_fb_login=0&force_authentication=1&client_id=1525568844743129&redirect_uri=https://app-collabs.mustard.love/instagram-auth&response_type=code&scope=instagram_business_basic";
    // "https://api.instagram.com/oauth/authorize?client_id=1525568844743129&redirect_uri=https://app-collabs.mustard.love/instagram-auth&response_type=code";
    window.location.href = instagramLoginUrl;
  };

  if (asLabel) {
    return (
      <div className="media" style={{ cursor: "pointer" }}>
        <InstagramIcon />
        <span onClick={handleLogin}>
          <b>Please connect your Instagram</b>
        </span>
      </div>
    );
  }
  return (
    <Button
      style={{ borderRadius: 12 }}
      icon={<InstagramIcon />}
      onClick={handleLogin}
    />
  );
};

export default InstagramButton;
