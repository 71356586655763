import { isMobile } from "react-device-detect";

export const openUrl = (url: string) => {
  if (!url) return;
  if (isMobile) {
    const event = { type: "open_url", url, reload: true };
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView?.postMessage(JSON.stringify(event));
    } else {
      window.location.href = url;
    }
  } else {
    const newWindow = window.open(url, "_blank");
    if (newWindow) newWindow.opener = null;
  }
};
