import { formatNumberKMBT } from "helpers/followers-functions";
import { useLoaderData, useNavigate } from "react-router-dom";
import { ReactComponent as InstagramIcon } from "assets/images/instagram-icon.svg";
import { ReactComponent as LogoutIcon } from "assets/images/logout-icon.svg";
import "./Profile.scss";
import { Button, message, Row, Spin, Typography } from "antd";
import { assignIgAccountUser } from "services/SauceService";
import { useDispatch } from "react-redux";
import { getLoggedUserInfo } from "redux-context/user";
import { logout, TOKEN } from "helpers/auth-functions";
import { useState } from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { updateTokenEvent } from "helpers/event-messages";

interface InstagramPage {
  biography: string;
  followersCount: number;
  id: string;
  name: string;
  profilePictureUrl: string;
  username: string;
}

const PageSelection: React.FC = () => {
  const { pages } = useLoaderData() as { pages: Array<InstagramPage> };
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);

  const onLogoutClick = () => {
    logout();
  };

  const onOkClick = () => {
    const token = localStorage.getItem(TOKEN);
    if (token) {
      navigator.clipboard.writeText(token).catch((err) => {
        console.error("Failed to copy token to clipboard: ", err);
      });
    }
  };

  const onSelectPageClick = async (page: InstagramPage) => {
    setLoading(true);
    const { id, username } = page;
    const internalAccessToken = localStorage.getItem(TOKEN);
    try {
      const response: any = await assignIgAccountUser({
        id,
        username,
        internalAccessToken,
      });

      if (response?.accessToken) {
        updateTokenEvent(response.accessToken);
        localStorage.setItem(TOKEN, response.accessToken);

        dispatch(getLoggedUserInfo());
        navigate("/");
      } else {
        console.log("this is not a valid token");
      }
    } catch {
      message.error("Failed to assign page");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Spin
      indicator={<LoadingOutlined style={{ fontSize: 72 }} spin />}
      spinning={loading}
    >
      <div className="page-selection">
        <Row
          justify="space-between"
          style={{ alignItems: "center", marginTop: 20 }}
        >
          <Typography.Title level={3} style={{ margin: "auto 20px" }}>
            Instagram accounts
          </Typography.Title>
          <LogoutIcon
            style={{ width: 20, height: 20 }}
            onClick={() => onLogoutClick()}
          />
        </Row>
        {pages.length > 0 ? (
          <>
            <Row>
              <span
                className="general-sub-title"
                style={{ margin: "12px 20px 12px 20px" }}
              >
                Choose an Instagram account to connect with Mustard
              </span>
            </Row>
            <div className="container">
              {pages?.map((page, index) => (
                <div
                  className="box-social"
                  onClick={() => onSelectPageClick(page)}
                  key={`PAGE_${page.id}_${index}`}
                >
                  <img src={page.profilePictureUrl} alt="profile" />
                  <div>
                    <div className="content-social">
                      <InstagramIcon />
                      <span className="title bold">{page.username}</span>
                      <span className="title">{page.name}</span>
                    </div>
                    <div className="content-social">
                      {/* <div className="content-social">
                  <span className="title bold">
                    {formatNumberKMBT(page.followings)}
                  </span>
                  <span className="title">following</span>
                </div> */}
                      <div className="content-social">
                        <span className="title bold">
                          {formatNumberKMBT(page.followersCount)}
                        </span>
                        <span className="title">followers</span>
                      </div>
                      {/* <div className="content-social">
                  <span className="title bold">
                    {formatNumberKMBT(page.postsCount)}
                  </span>
                  <span className="title">posts</span>
                </div> */}
                    </div>
                    <div>
                      <p>{page.biography}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </>
        ) : (
          <div className="container">
            <span
              className="general-sub-title"
              style={{ margin: "12px 20px 12px 20px" }}
            >
              No Instagram accounts found
            </span>
            <Button
              type="primary"
              onClick={onOkClick}
              style={{ margin: "12px 20px" }}
            >
              Ok
            </Button>
          </div>
        )}
      </div>
    </Spin>
  );
};

export default PageSelection;
