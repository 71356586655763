import { Button, Form, Input, message, Typography } from "antd";
import "./BookCollab.scss";
import { useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import { Booking } from "interfaces/booking";
import { cancelBooking } from "services/SauceService";
import { openUrl } from "helpers/link-helper";

const BookCancel: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;
  const { booking } = state as { booking: Booking };
  const [loading, setLoading] = useState<boolean>(false);
  const [reason, setReason] = useState<string>("");

  const onClickCancel = async () => {
    setLoading(true);
    const params = {
      bookingId: booking.bookingId,
      collabId: booking.collabId,
      reason,
    };
    try {
      await cancelBooking(params);
      navigate("/book/cancelled");
    } catch (error) {
      message.error("Failed to cancel collab");
    }
    setLoading(false);
  };

  const onClickPolicy = () => {
    openUrl("https://www.mustard.love/reschedule-and-cancellation-policy");
  };

  return (
    <div className="book-confirmation red">
      <div>
        <Typography.Title level={3} style={{ margin: "36px 0 16px 0" }}>
          Cancel collab?
        </Typography.Title>

        <Typography.Text style={{ margin: 0 }}>
          Canceling collabs will lower your rating, making restaurants hesitant
          to work with you. Read the cancellation and the rescheduling{" "}
          <Button
            type="link"
            onClick={onClickPolicy}
            style={{
              padding: 0,
              color: "#1677ff",
              fontSize: 16,
              height: "auto",
            }}
          >
            policy
          </Button>
          .
        </Typography.Text>

        <div style={{ display: "flex", justifyContent: "center" }}>
          <Form.Item
            label="Reason"
            layout="vertical"
            style={{ width: "100%", marginTop: 20 }}
          >
            <Input.TextArea
              rows={6}
              value={reason}
              onChange={(evt) => setReason(evt.target.value)}
            />
          </Form.Item>
        </div>
      </div>
      <div className="buttons">
        <Button
          color="danger"
          variant="outlined"
          onClick={onClickCancel}
          loading={loading}
          disabled={!reason}
        >
          Cancel anyway
        </Button>
        <Button type="text" onClick={() => navigate(-1)}>
          Go Back
        </Button>
      </div>
    </div>
  );
};

export default BookCancel;
