import axios, { AxiosResponse } from "axios";

export const instance = axios.create({
  baseURL: "https://hook.us1.make.com/",
});

instance.interceptors.response.use((response: AxiosResponse<any>) => {
  return response.data;
});

export const postSendPhoneVerification = (params: any) => {
  return instance.post(`t5qh09f65vkkyejv3qp4hgm25hdbk956`, params);
};
