import { getUserHistory } from "services/SauceService";

export async function historyLoader(props: any) {
  const foodieId = localStorage.getItem("foodieId");

  const response = await getUserHistory({
    foodieId, //: "62f99c690861b82b48bc270f",
  });

  return response;
}
