import { CloseOutlined, LoadingOutlined } from "@ant-design/icons";
import { Input } from "antd";
import { Location } from "interfaces/user-profile";
import { debounce } from "lodash";
import { useState } from "react";
import { searchByZipcode } from "services/SauceService";

interface Props {
  onSaved: (location: Location) => void;
  onCancel: () => void;
}

const AddLocation: React.FC<Props> = (props) => {
  const { onSaved, onCancel } = props;
  const [newLocations, setNewLocations] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const searchLocation = debounce(async (code: string) => {
    setLoading(true);
    const response: any = await searchByZipcode({ code });
    setNewLocations(response);
    setLoading(false);
  }, 3000);

  const onChangeText = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    searchLocation(value);
  };

  const onAddClick = (location: any) => {
    onSaved({
      name: location?.formatted_address,
      lat: location?.geometry?.location?.lat,
      long: location?.geometry?.location?.lng,
    });
  };

  return (
    <div>
      <div className="location-search">
        <Input
          placeholder="Add a zipcode"
          onChange={onChangeText}
          suffix={loading ? <LoadingOutlined /> : null}
        />
        <CloseOutlined onClick={() => onCancel()} />
      </div>

      {newLocations.map((location: any) => (
        <div
          key={location.place_id}
          className="location-add"
          onClick={() => onAddClick(location)}
        >
          {location.formatted_address}
        </div>
      ))}
    </div>
  );
};

export default AddLocation;
