import { Button, Typography } from "antd";
import "./BookCollab.scss";
import { useLocation, useNavigate } from "react-router-dom";
import { Booking } from "interfaces/booking";
import { openUrl } from "helpers/link-helper";

const BookReschedule: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;
  const { booking } = state as { booking: Booking };

  const onClickReschedule = async () => {
    navigate(`/collab/book/${booking.collabId}`, {
      state: { previousBooking: booking },
    });
  };

  const onClickPolicy = () => {
    openUrl("https://www.mustard.love/reschedule-and-cancellation-policy");
  };

  return (
    <div className="book-confirmation red">
      <div>
        <Typography.Title level={3} style={{ margin: "36px 0 16px 0" }}>
          Reshedule collab?
        </Typography.Title>

        <Typography.Text style={{ margin: 0 }}>
          Rescheduling collabs will lower your rating, making restaurants
          hesitant to work with you. Read the cancellation and the rescheduling
          policy.
          <Button
            type="link"
            onClick={onClickPolicy}
            style={{
              padding: 0,
              color: "#1677ff",
              fontSize: 16,
              height: "auto",
            }}
          >
            policy
          </Button>
          .
        </Typography.Text>
      </div>
      <div className="buttons">
        <Button color="danger" variant="outlined" onClick={onClickReschedule}>
          Reshedule anyway
        </Button>
        <Button type="text" onClick={() => navigate(-1)}>
          Go Back
        </Button>
      </div>
    </div>
  );
};

export default BookReschedule;
