import { useEffect, useState } from "react";
import { Button, Popover, Row, Tag, Typography } from "antd";
// import { ReactComponent as NotificationIcon } from "assets/images/notification-icon.svg";
import { useLoaderData, useNavigate } from "react-router-dom";
import EmptyItems from "assets/images/empty-item.png";
import ConfirmCircle from "assets/images/confirm-circle.png";
import CheckinCircle from "assets/images/checkin-circle.png";
import { HomeData } from "interfaces/home-data";
import { ReactComponent as CalendarIcon } from "assets/images/calendar-icon.svg";
import { ReactComponent as LocationIcon } from "assets/images/location-icon.svg";
// import { ReactComponent as CircleCheckIcon } from "assets/images/circle-check-icon.svg";
import { ReactComponent as SortIcon } from "assets/images/sort-icon.svg";
import { ReactComponent as CheckIcon } from "assets/images/check-icon.svg";
import dayjs from "dayjs";
import { getGoogleMapsUrl } from "helpers/google-maps-utils";
import { SlideButton } from "components";
import "./Collabs.scss";
import { isDesktop } from "react-device-detect";
import { RightOutlined } from "@ant-design/icons";
import { ProfileStatus, UserProfile } from "interfaces/user-profile";
import { useSelector } from "react-redux";
import DrawerRating from "./DrawerRating";
import { Booking } from "interfaces/booking";
import { checkIn } from "services/SauceService";
import { BookingStatus } from "enums/BookingStatus";
import LoadingFullScreen from "pages/login/LoadingFullScreen";

export const calculateTimeLeft = (applicationDate: any) => {
  const targetDate = dayjs(applicationDate.date);
  const now = dayjs();

  if (targetDate.isBefore(now)) {
    return ""; //"Time expired";
  }

  const diff = dayjs.duration(targetDate.diff(now));
  const hours = Math.floor(diff.asHours());
  const minutes = diff.minutes();

  return `${hours}h ${minutes}m left`;
};

enum ListType {
  Upcoming = "upcoming",
  Pending = "pending",
}

const Collabs: React.FC = () => {
  const { today, tomorrow, contentDue, upcoming, pending, foodieId } =
    useLoaderData() as HomeData;
  const { loggedUser, loading }: { loggedUser: UserProfile; loading: boolean } =
    useSelector((state: any) => state.user);

  const { profileStatus } = loggedUser;

  const highlighted =
    today.length > 0 ? today[0] : tomorrow.length > 0 ? tomorrow[0] : null;
  const navigate = useNavigate();
  const [selectedListType, setSelectedListType] = useState<ListType>(
    upcoming.length === 0 && pending.length > 0
      ? ListType.Pending
      : ListType.Upcoming
  );

  const onCheckin = async (booking: Booking) => {
    await checkIn({
      bookingId: booking.bookingId,
    });
    navigate("/welcome", { state: { booking } });
  };

  const onConfirm = (collab: any) => {
    navigate("/confirm", { state: { collab } });
  };

  useEffect(() => {
    if (!foodieId && !loading) navigate(".", { replace: true });
  }, [foodieId, loading, navigate]);

  if (loading || !foodieId || profileStatus !== ProfileStatus.Active)
    return <LoadingFullScreen />;

  return (
    <div className="collabs">
      <Row>
        <div style={{ marginLeft: 20 }}>
          <DrawerRating />
        </div>
        <div className="notification-history-box">
          {/* <NotificationIcon onClick={() => navigate("/notifications")} /> */}
          <span
            onClick={() => navigate("/history", { state: { back: false } })}
          >
            History
          </span>
        </div>
      </Row>
      <div className="content">
        {highlighted ? (
          <div className="highlight">
            <Typography.Title level={1} className="title">
              Hi {loggedUser?.name}, <br></br>
              waiting for you {today.length > 0 ? "today" : "tomorrow"}
            </Typography.Title>
            <Row style={{ justifyContent: "space-between" }}>
              <span className="venue ellipsis" style={{ maxWidth: 230 }}>
                {highlighted.venue.name}
              </span>
            </Row>
            <Row style={{ color: "#1D2023", gap: 4 }}>
              <CalendarIcon className="color-black" />
              <span>
                {dayjs.utc(highlighted.applicationDate.date).format("MMM DD")}{" "}
                {highlighted.applicationDate?.hour}
              </span>{" "}
            </Row>
            <Row style={{ marginTop: 12, gap: 6 }}>
              <LocationIcon />
              <a
                href={getGoogleMapsUrl(highlighted.venue)}
                target="_blank"
                rel="noreferrer"
                className="ellipsis"
                style={{ width: 230 }}
              >
                {highlighted.venue.addressDisplay ?? highlighted.venue.address}
              </a>
            </Row>
            <Row style={{ gap: 4, marginTop: 12 }} align="middle">
              {highlighted.guestAllowed && (
                <div className="box-perk">
                  <span>+1 Guest</span>
                </div>
              )}
              {highlighted.compensationFood > 0 && (
                <>
                  {" ∘ "}
                  <div className="box-perk">
                    <span>${highlighted.compensationFood} Food</span>
                  </div>
                </>
              )}
              {highlighted.compensationCash > 0 && (
                <>
                  {" ∘ "}
                  <div className="box-perk">
                    <span>${highlighted.compensationCash} Comp</span>
                  </div>
                </>
              )}
            </Row>
            <Row style={{ marginTop: 24 }}>
              {today.length > 0 &&
                highlighted.status !== BookingStatus.WaitingContent && (
                  <SlideButton
                    onSlide={() => onCheckin(highlighted)}
                    content={
                      <div className="slider-content">
                        <span className="main">Check in</span>
                        <span>To be greeted by venue's staff</span>

                        {/* <Countdown
                      value={dayjs(highlighted.applicationDate.date)
                        .toDate()
                        .toISOString()}
                      format="HH:mm:ss"
                      // prefix={<TimerIcon />}
                      className="countdown"
                    /> */}
                        {/* <span>{`only ${collab?.numberOfFoodiesRemaining} spots left`}</span> */}
                      </div>
                    }
                  >
                    <img
                      src={CheckinCircle}
                      alt=""
                      draggable="false"
                      style={{ height: "100%" }}
                    />
                  </SlideButton>
                )}
              {today.length > 0 &&
                highlighted.status === BookingStatus.WaitingContent && (
                  <Button
                    type="primary"
                    size="large"
                    shape="round"
                    style={{ width: "100%" }}
                    onClick={() =>
                      navigate("/certificate", {
                        state: { booking: highlighted },
                      })
                    }
                  >
                    Show voucher
                  </Button>
                )}
              {tomorrow.length > 0 && !highlighted.attendanceConfirmation && (
                <SlideButton
                  onSlide={() => onConfirm(highlighted)}
                  content={
                    <div className="slider-content">
                      <span className="main">I confirm</span>
                      <span>
                        {calculateTimeLeft({
                          date: dayjs(highlighted.applicationDate.date)
                            .tz("America/Los_Angeles")
                            .set("hour", 18)
                            .set("minute", 0)
                            .toISOString(),
                        })}
                      </span>
                      {/* <Countdown
                        value={dayjs(highlighted.applicationDate.date)
                          .tz("America/Los_Angeles")
                          .set("hour", 18)
                          .set("minute", 0)
                          .toISOString()}
                        format="HH:mm:ss"
                        // prefix={<TimerIcon />}
                        className="countdown"
                      /> */}
                      {/* <span>{`only ${collab?.numberOfFoodiesRemaining} spots left`}</span> */}
                    </div>
                  }
                >
                  <img src={ConfirmCircle} alt="" draggable="false" />
                </SlideButton>
              )}
            </Row>
            {/* <Row hidden>
              <Button
                type="primary"
                size="large"
                shape="round"
                onClick={() =>
                  navigate("/certificate", { state: { collab: highlighted } })
                }
                style={{ marginTop: 24, width: "100%" }}
              >
                Show certificate
              </Button>
            </Row> */}
          </div>
        ) : (
          <div className="empty-schedule">
            <Typography.Title level={1}>
              You have no collabs scheduled
            </Typography.Title>
            <div className="image-container">
              <img src={EmptyItems} alt="" />
            </div>
          </div>
        )}

        {today.length > 0 && tomorrow.length > 0 && (
          <div className="content-due tomorrow">
            <Typography.Title level={3}>Tomorrow</Typography.Title>
            {tomorrow.map((collab, index) => (
              <div
                className="collab"
                key={`${index}_TOMORROW_${collab.collabId}`}
              >
                <div className="collab-content">
                  <div style={{ flex: 1 }}>
                    <Row style={{ justifyContent: "space-between" }}>
                      <span
                        className="ellipsis"
                        style={{
                          fontSize: 18,
                          fontWeight: 600,
                          maxWidth: 300,
                        }}
                      >
                        {collab.venue.name}
                      </span>
                    </Row>
                    <Row style={{ margin: "6px 0" }}>
                      <span
                        className="ellipsis"
                        style={{ color: "#1D2023", maxWidth: 230 }}
                      >
                        {collab.venue.address}
                      </span>
                    </Row>
                    <Row style={{ gap: 8, marginTop: 12 }}>
                      {collab.guestAllowed && (
                        <div className="box-perk">
                          <span className="emoji-size">🍴</span>
                          <div className="value">
                            <span>Guests</span>
                            <span>
                              <b>+1</b>
                            </span>
                          </div>
                        </div>
                      )}
                      {collab.compensationFood > 0 && (
                        <div className="box-perk">
                          <span className="emoji-size">🍔</span>
                          <div className="value">
                            <span>Food</span>
                            <span>
                              <b>${collab.compensationFood}</b>
                            </span>
                          </div>
                        </div>
                      )}
                      {collab.compensationCash > 0 && (
                        <div className="box-perk">
                          <span className="emoji-size">💸</span>
                          <div className="value">
                            <span>Comp</span>
                            <span>
                              <b>${collab.compensationCash}</b>
                            </span>
                          </div>
                        </div>
                      )}
                    </Row>
                    <Row justify="space-between" style={{ marginTop: 8 }}>
                      <span style={{ color: "#666666" }}>
                        {calculateTimeLeft(collab.applicationDate)}
                      </span>
                      {!collab.attendanceConfirmation && (
                        <Button
                          type="primary"
                          size="small"
                          shape="round"
                          onClick={() => onConfirm(collab)}
                        >
                          I confirm
                        </Button>
                      )}
                    </Row>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
        {contentDue.length > 0 && (
          <div className="content-due">
            <Typography.Title level={3}>Content due</Typography.Title>
            {contentDue.map((collab, index) => (
              <div
                className="collab"
                key={`${index}_CONTENT_DUE_${collab.collabId}`}
              >
                <div
                  className="collab-content"
                  style={{ display: "flex", flexWrap: "wrap" }}
                >
                  <div style={{ flex: 3 }}>
                    <Row style={{ justifyContent: "space-between" }}>
                      <span
                        className="ellipsis"
                        style={{
                          fontSize: 18,
                          fontWeight: 600,
                          maxWidth: 230,
                        }}
                      >
                        {collab.venue.name}
                      </span>
                    </Row>
                    <div className="row">
                      <LocationIcon style={{ width: 16, height: 16 }} />
                      <span
                        className="ellipsis general-sub-title"
                        style={{ maxWidth: 210 }}
                      >
                        {collab.venue.address}
                      </span>
                    </div>
                    <div className="row">
                      <CalendarIcon style={{ width: 16, height: 16 }} />
                      <span
                        className="general-sub-title"
                        style={{ maxWidth: 230 }}
                      >
                        Due{" "}
                        {dayjs(collab.applicationDate.date).format("MMM DD")}{" "}
                      </span>
                      {/* {" ∘ "} <CircleCheckIcon />
                      <span className="content-due-line">
                      {collab.deliverables.length} content due
                      </span> */}
                    </div>
                  </div>
                  <div style={{ flex: 1 }} className="right-side">
                    {collab.compensationCash > 0 ? (
                      <span className="get-cash">
                        get <b>${collab.compensationCash}</b>
                      </span>
                    ) : (
                      <span className="effect-rating">⚡️ Improve rating</span>
                    )}
                    <Button
                      type="primary"
                      size="small"
                      shape="round"
                      onClick={() =>
                        navigate(`/book/detail/${collab.bookingId}`)
                      }
                    >
                      Submit
                    </Button>
                  </div>
                  {dayjs(collab.submitDueDate).diff(dayjs(), "day") < 1 && (
                    <div className="row">
                      <span
                        className="general-sub-title"
                        style={{
                          color: "red",
                          fontWeight: 600,
                        }}
                      >
                        Late submissions lower your rating.
                      </span>
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
      {selectedListType === ListType.Upcoming && (
        <div
          className="upcoming"
          style={{ paddingBottom: isDesktop ? 20 : 100 }}
        >
          {upcoming.length > 0 ? (
            <>
              <div className="header">
                <Typography.Title level={3}>Upcoming</Typography.Title>
                <Popover
                  rootClassName="sort-popover"
                  arrow={false}
                  content={
                    <ul>
                      <li>
                        <span className="selected">
                          Upcoming
                          <CheckIcon />
                        </span>
                      </li>
                      <li onClick={() => setSelectedListType(ListType.Pending)}>
                        <span>Pending approval</span>
                      </li>
                    </ul>
                  }
                  title={null}
                  trigger="click"
                >
                  <SortIcon />
                </Popover>
              </div>

              {upcoming.map((collab, index) => (
                <div
                  className="upcoming-collab"
                  key={`${index}_UPCOMING_${collab.collabId}`}
                >
                  <Row align="middle" style={{ gap: 8 }}>
                    <span className="left-side">
                      <b>
                        {dayjs
                          .utc(collab.applicationDate?.date)
                          .format("MMM DD")}{" "}
                      </b>
                    </span>
                    <span className="line" />
                  </Row>
                  <Row align="middle" style={{ gap: 8 }}>
                    <span className="left-side">
                      {collab.applicationDate?.hour}
                    </span>
                    <div className="collab-content">
                      <div style={{ flex: 1 }}>
                        <Row style={{ justifyContent: "space-between" }}>
                          <span
                            className="ellipsis"
                            style={{
                              fontSize: 18,
                              fontWeight: 600,
                              maxWidth: 300,
                            }}
                          >
                            {collab.venue.name}
                          </span>
                          <RightOutlined
                            style={{ fontSize: 12, cursor: "pointer" }}
                            onClick={() =>
                              navigate(`/book/detail/${collab.bookingId}`)
                            }
                          />
                        </Row>
                        <Row style={{ margin: "6px 0" }}>
                          <span
                            className="ellipsis"
                            style={{ color: "#1D2023", maxWidth: 230 }}
                          >
                            {collab.venue.address}
                          </span>
                        </Row>
                        <Row style={{ gap: 8, marginTop: 12 }}>
                          {collab.guestAllowed && (
                            <div className="box-perk">
                              <span className="emoji-size">🍴</span>
                              <div className="value">
                                <span>Guests</span>
                                <span>
                                  <b>+1</b>
                                </span>
                              </div>
                            </div>
                          )}
                          {collab.compensationFood > 0 && (
                            <div className="box-perk">
                              <span className="emoji-size">🍔</span>
                              <div className="value">
                                <span>Food</span>
                                <span>
                                  <b>${collab.compensationFood}</b>
                                </span>
                              </div>
                            </div>
                          )}
                          {collab.compensationCash > 0 && (
                            <div className="box-perk">
                              <span className="emoji-size">💸</span>
                              <div className="value">
                                <span>Comp</span>
                                <span>
                                  <b>${collab.compensationCash}</b>
                                </span>
                              </div>
                            </div>
                          )}
                        </Row>
                        {collab?.venue?.ghostKitchen && (
                          <>
                            <div
                              className="line"
                              style={{ margin: "12px 0" }}
                            />
                            <Row align="middle">
                              <Tag className="ghost-kitchen" color="black">
                                Ghost kitchen
                              </Tag>
                              <span>Delivery included</span>
                            </Row>
                          </>
                        )}
                      </div>
                    </div>
                  </Row>
                </div>
              ))}
            </>
          ) : (
            <div className="no-items">
              <span className="title">Apply for collabs</span>
              <span className="sub-title">
                Once accepted your active collaborations will appear here
              </span>
              <Button
                type="primary"
                style={{ marginTop: 24 }}
                onClick={() => navigate("/find-collabs")}
              >
                Find collabs
              </Button>
            </div>
          )}
        </div>
      )}
      {selectedListType === ListType.Pending && (
        <div
          className="pending"
          style={{ paddingBottom: isDesktop ? 20 : 100 }}
        >
          <>
            <div className="header">
              <Typography.Title level={3}>Pending approval</Typography.Title>
              {upcoming.length > 0 && (
                <Popover
                  rootClassName="sort-popover"
                  arrow={false}
                  content={
                    <ul>
                      <li
                        onClick={() => setSelectedListType(ListType.Upcoming)}
                      >
                        <span>Upcoming</span>
                      </li>
                      <li>
                        <span className="selected">
                          Pending approval <CheckIcon />
                        </span>
                      </li>
                    </ul>
                  }
                  title={null}
                  trigger="click"
                >
                  <SortIcon />
                </Popover>
              )}
            </div>

            <div style={{ gap: 12, display: "flex", flexWrap: "wrap" }}>
              {pending.map((collab, index) => (
                <div
                  className="pending-collab"
                  style={{ flex: "100%" }}
                  key={`${index}_UPCOMING_${collab.collabId}`}
                >
                  <Row align="middle" style={{ gap: 8 }}>
                    <div className="collab-content">
                      <div style={{ flex: 1 }}>
                        <Row style={{ justifyContent: "space-between" }}>
                          <span
                            className="ellipsis"
                            style={{
                              fontSize: 18,
                              fontWeight: 600,
                              maxWidth: 300,
                            }}
                          >
                            {collab.venue.name}
                          </span>
                          <RightOutlined
                            style={{ fontSize: 12, cursor: "pointer" }}
                            onClick={() =>
                              navigate(`/book/detail/${collab.bookingId}`)
                            }
                          />
                        </Row>
                        <Row style={{ margin: "6px 0" }}>
                          <span
                            className="ellipsis"
                            style={{ color: "#1D2023", maxWidth: 230 }}
                          >
                            {collab.venue.address}
                          </span>
                        </Row>
                        <Row style={{ color: "#666666", gap: 4 }}>
                          <CalendarIcon />
                          <span>
                            from{" "}
                            {dayjs
                              .utc(collab.applicationDate?.date)
                              .format("MMM DD")}
                          </span>{" "}
                          {" ∘ "}
                          <span className="applicants">{135} applicants</span>
                        </Row>
                        <Row style={{ gap: 8, marginTop: 12 }}>
                          {collab.guestAllowed && (
                            <div className="box-perk">
                              <span className="emoji-size">🍴</span>
                              <div className="value">
                                <span>Guests</span>
                                <span>
                                  <b>+1</b>
                                </span>
                              </div>
                            </div>
                          )}
                          {collab.compensationFood > 0 && (
                            <div className="box-perk">
                              <span className="emoji-size">🍔</span>
                              <div className="value">
                                <span>Food</span>
                                <span>
                                  <b>150$</b>
                                </span>
                              </div>
                            </div>
                          )}
                          {collab.compensationCash > 0 && (
                            <div className="box-perk">
                              <span className="emoji-size">💸</span>
                              <div className="value">
                                <span>Comp</span>
                                <span>
                                  <b>${collab.compensationCash}</b>
                                </span>
                              </div>
                            </div>
                          )}
                        </Row>
                        {collab?.venue?.ghostKitchen && (
                          <>
                            <div
                              className="line"
                              style={{ margin: "12px 0" }}
                            />
                            <Row align="middle">
                              <Tag className="ghost-kitchen" color="black">
                                Ghost kitchen
                              </Tag>
                              <span>Delivery included</span>
                            </Row>
                          </>
                        )}
                      </div>
                    </div>
                  </Row>
                </div>
              ))}
            </div>
          </>
        </div>
      )}
    </div>
  );
};

export default Collabs;
