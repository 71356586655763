import { Avatar, Button, Modal, Row, Typography } from "antd";
import { ReactComponent as EditIcon } from "assets/images/edit-icon.svg";
import { ReactComponent as InstagramIcon } from "assets/images/instagram-icon.svg";
import { ReactComponent as CoverageIcon } from "assets/images/coverage-icon.svg";
import { ReactComponent as CategoriesIcon } from "assets/images/categories-icon.svg";
import { ReactComponent as DietaryRestrictionsIcon } from "assets/images/dietary-restriction-icon.svg";
import { ReactComponent as NotificationsIcon } from "assets/images/notifications-icon.svg";
import { ReactComponent as PaymentsSettingsIcon } from "assets/images/payments-settings-icon.svg";
import { ReactComponent as LogoutIcon } from "assets/images/logout-icon.svg";
import "./Profile.scss";
import { isMobile, isTablet } from "react-device-detect";
import { useNavigate } from "react-router-dom";
import { DietaryPreferences } from "types/dietary-preferences";
import { useDispatch, useSelector } from "react-redux";
import { UserProfile } from "interfaces/user-profile";
import { useEffect } from "react";
import { getStripeAccount } from "redux-context/user";
import { StripeStatus } from "interfaces/stripe-status";
import { logout } from "helpers/auth-functions";
import DrawerRating from "pages/collabs/DrawerRating";
import { removeLoggedUserAccount } from "services/SauceService";

const dietaryRestrictionsLabels = {
  none: "None",
  vegetarian: "Vegetarian",
  vegan: "Vegan",
  lactoseIntolerance: "Lactose Intolerance",
  glutenIntolerance: "Gluten Intolerance",
  noPork: "No Pork",
  nutsAllergy: "Nuts Allergy",
  pescatarian: "Pescatarian",
  shellfishAllergy: "Shellfish Allergy",
};

const getOtherText = (dietaryPreferences?: DietaryPreferences) => {
  return dietaryPreferences?.otherText || "";
};

const getDietaryRestrictionsRaw = (dietaryPreferences?: DietaryPreferences) => {
  if (!dietaryPreferences) return [];
  return Object.keys(dietaryPreferences).filter(
    (key) =>
      dietaryPreferences[key as keyof typeof dietaryRestrictionsLabels] ===
        true &&
      key !== "other" &&
      key !== "none"
  );
};

const getDietaryRestrictions = (
  dietaryPreferences?: DietaryPreferences,
  labels?: { [key: string]: string }
) => {
  const rawRestrictions = getDietaryRestrictionsRaw(dietaryPreferences);
  const otherText = getOtherText(dietaryPreferences);

  if (!rawRestrictions.length) return "None";

  const restrictions = rawRestrictions.map(
    (item) => labels?.[item as keyof typeof labels] || item
  );

  if (otherText) restrictions.push(otherText);

  return restrictions.join(", ");
};

const Profile: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<any>();
  const {
    loggedUser,
    stripeConnected,
    stripeStatus,
  }: {
    loggedUser: UserProfile;
    stripeConnected: boolean;
    stripeStatus: StripeStatus;
  } = useSelector((state: any) => state.user);

  const socialMedias = loggedUser?.socialMedias?.length
    ? loggedUser.socialMedias.map((item) => item.socialMediaName).join(", ")
    : "None";

  const bestCoverage = loggedUser?.locations?.length
    ? loggedUser.locations.map((item) => item.name).join(", ")
    : "None";

  const bestCategories = loggedUser?.categories?.length
    ? loggedUser.categories.map((item) => item).join(", ")
    : "None";

  const dietaryRestrictions = getDietaryRestrictions(
    loggedUser?.dietaryPreferences
  );

  const paymentsSettings = stripeConnected
    ? "Stripe Wallet Connected"
    : "Stripe Wallet Not Connected";

  const proccessNotificationPreferences = () => {
    if (loggedUser?.notificationPreferences?.stopSendingCommunication)
      return "None";
    const notificationPreferences = [];
    if (loggedUser?.notificationPreferences?.keepPostedOverEmail)
      notificationPreferences.push("Email");
    if (loggedUser?.notificationPreferences?.keepPostedOverText)
      notificationPreferences.push("Text");
    if (loggedUser?.notificationPreferences?.keepPostedOverNotifications)
      notificationPreferences.push("Push notifications");

    return notificationPreferences.join(", ");
  };
  const notificationPreferences = proccessNotificationPreferences();

  const onLogoutClick = () => {
    logout();
  };

  const onCklickDeleteUser = () => {
    Modal.confirm({
      title: "Delete account",
      content:
        "Are you sure you want to delete your account? All your personal data and bookings will be permanently removed and cannot be recovered.",
      onOk: async () => {
        await removeLoggedUserAccount();
        logout();
      },
      okButtonProps: {
        danger: true,
      },
    });
  };

  useEffect(() => {
    if (loggedUser.stripeId && !stripeStatus) {
      const { stripeId } = loggedUser;
      dispatch(getStripeAccount({ stripeId }));
    }
  }, [dispatch, loggedUser, stripeStatus]);

  return (
    <div className="profile">
      <Row justify="space-between">
        <DrawerRating />
        <div style={{ display: "flex", alignItems: "center", gap: 12 }}>
          <EditIcon
            style={{ cursor: "pointer", marginRight: 12 }}
            onClick={() => navigate("/profile/personal-info")}
          />
          <div
            style={{
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
            }}
          >
            <LogoutIcon
              style={{ width: 20, height: 20 }}
              onClick={() => onLogoutClick()}
            />
          </div>
        </div>
      </Row>
      <Row justify="center">
        <div className="gradient-border" style={{ width: 95, height: 95 }}>
          <Avatar size={90} src={loggedUser?.avatarUrl} />
        </div>
      </Row>
      <Row justify="center">
        <Typography.Title level={1}>{loggedUser?.name}</Typography.Title>
      </Row>
      <Typography.Paragraph>
        {loggedUser?.bio}
        {/* I have carved an impressive career in the
        culinary world. With a burning love for grub, I'm */}
      </Typography.Paragraph>
      <div
        className="content"
        style={{
          marginBottom: isMobile ? 100 : isTablet ? 50 : 0,
        }}
      >
        <Row>
          <div className="box-item">
            <div className="icon">
              <InstagramIcon />
            </div>
            <div
              className="item"
              onClick={() => navigate("/profile/social-media")}
            >
              <span className="title">Social Media</span>
              <span className="value">{socialMedias}</span>
            </div>
          </div>
        </Row>
        <Row>
          <div className="box-item">
            <div className="icon">
              <CoverageIcon />
            </div>
            <div className="item" onClick={() => navigate("/profile/coverage")}>
              <span className="title">Best coverage in</span>
              <span
                className="value ellipsis"
                style={{
                  maxWidth: isMobile ? 230 : 350,
                }}
              >
                {bestCoverage}
              </span>
            </div>
          </div>
        </Row>
        <Row>
          <div className="box-item">
            <div className="icon">
              <CategoriesIcon />
            </div>
            <div
              className="item"
              onClick={() => navigate("/profile/categories")}
            >
              <span className="title">Best categories</span>
              <span
                className="value ellipsis"
                style={{
                  maxWidth: isMobile ? 230 : 350,
                }}
              >
                {bestCategories}
              </span>
            </div>
          </div>
        </Row>
        <Row>
          <div className="box-item">
            <div className="icon">
              <DietaryRestrictionsIcon />
            </div>
            <div
              className="item"
              onClick={() => navigate("/dietary-restrictions")}
            >
              <span className="title">Dietary restrictions</span>
              <span
                className="value ellipsis"
                style={{
                  maxWidth: isMobile ? 230 : 350,
                }}
              >
                {dietaryRestrictions}
              </span>
            </div>
          </div>
        </Row>
        <Row>
          <div className="box-item">
            <div className="icon">
              <NotificationsIcon />
            </div>
            <div
              className="item"
              onClick={() => navigate("/profile/notifications")}
            >
              <span className="title">Notifications</span>
              <span
                className="value ellipsis"
                style={{
                  maxWidth: isMobile ? 230 : 350,
                }}
              >
                {notificationPreferences}
              </span>
            </div>
          </div>
        </Row>
        <Row>
          <div className="box-item">
            <div className="icon">
              <PaymentsSettingsIcon />
            </div>
            <div
              className="item"
              onClick={() => navigate("/profile/payment-settings")}
            >
              <span className="title">Payment settings</span>
              <span className="value">{paymentsSettings}</span>
            </div>
          </div>
        </Row>
      </div>
      <div className="danger-zone" style={{ margin: "20px 0 80px 0" }}>
        <h3 style={{ color: "red", fontWeight: 700, margin: 0 }}>
          Delete account
        </h3>
        <p>
          If you delete your account, all your data will be permanently deleted.
        </p>
        <Button
          type="link"
          danger
          onClick={onCklickDeleteUser}
          style={{ padding: 0 }}
        >
          Delete account
        </Button>
      </div>
    </div>
  );
};

export default Profile;
