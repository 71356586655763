declare global {
  interface Window {
    ReactNativeWebView: any;
  }
}

export const TOKEN = "sauce-info";

export const autenticado = () => {
  return localStorage.getItem(TOKEN) != null;
};

export const logout = () => {
  localStorage.clear();
  const event = {
    type: "logout",
    data: {},
  };
  window.ReactNativeWebView?.postMessage(JSON.stringify(event));
  setTimeout(() => {
    window.location.href = "/login";
  }, 1000);
};
