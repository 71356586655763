import { Button, Typography } from "antd";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import confirmed from "assets/images/confirmed.png";
import { useDispatch } from "react-redux";
import { setBackgroundColor } from "redux-context/page";

const Confirmed: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setBackgroundColor("#e2f2dc"));
    return () => {
      dispatch(setBackgroundColor());
    };
  }, [dispatch]);

  return (
    <div className="page-full-screen fixed green">
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
          gap: 60,
        }}
      >
        <div>
          <Typography.Title level={3}>Confirmed!</Typography.Title>
          <Typography.Text>
            One hour before your appointment, access Check-in to get the
            certificate for the restaurant.
          </Typography.Text>
        </div>
        <img src={confirmed} alt="" style={{ width: 220 }} />
      </div>
      <div>
        <Button
          type="primary"
          size="large"
          shape="round"
          onClick={() => navigate("/")}
          style={{ marginTop: 24, width: "100%" }}
        >
          Done
        </Button>
      </div>
    </div>
  );
};

export default Confirmed;
