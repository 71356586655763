import { Button, Typography } from "antd";
import fiveMiles from "assets/images/five-miles.png";
import { Booking } from "interfaces/booking";
import { useLocation, useNavigate } from "react-router-dom";

interface Props {
  // Define your component's props here
}

const Checkin: React.FC<Props> = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location || {};
  const { collab }: { collab: Booking } = state || {};

  const onClickShareLocation = () => {
    navigate("/welcome", { state: { collab } });
  };

  return (
    <div className="page-full-screen fixed">
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
          gap: 60,
        }}
      >
        <div>
          <Typography.Title level={3}>Share your location</Typography.Title>
          <Typography.Text>
            For check-in, location confirmation is required.
          </Typography.Text>
        </div>
        <img src={fiveMiles} alt="" style={{ width: 220 }} />
      </div>
      <Button
        type="primary"
        size="large"
        shape="round"
        onClick={onClickShareLocation}
        style={{ marginTop: 24, width: "100%" }}
      >
        Share my location
      </Button>
    </div>
  );
};

export default Checkin;
