import { CloseCircleOutlined, LeftOutlined } from "@ant-design/icons";
import { Button, Calendar, Col, message, Row, Select, Typography } from "antd";
import dayjs, { Dayjs } from "dayjs";
import { generateTimeSlots } from "helpers/time-slot";
import { useMemo, useState } from "react";
import { useLoaderData, useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as MorningIcon } from "assets/images/morning-icon.svg";
import { ReactComponent as AfternoonIcon } from "assets/images/afternoon-icon.svg";
import { ReactComponent as EveningIcon } from "assets/images/evening-icon.svg";
import { UserProfile } from "interfaces/user-profile";
import { useSelector } from "react-redux";
import { Collab } from "interfaces/collab";
import { isDesktop, isTablet } from "react-device-detect";
import "./BookCollab.scss";

interface BookCollabProps {
  // Define the props for your component here
}

const BookCollab: React.FC<BookCollabProps> = () => {
  const { collab } = useLoaderData() as { collab: Collab };
  const navigate = useNavigate();
  const location = useLocation();

  const { state } = location;
  const { spotId, previousBooking } = state || {};
  const { loggedUser }: { loggedUser: UserProfile } = useSelector(
    (state: any) => state.user
  );
  const [selectedDate, setSelectedDate] = useState<Dayjs>(
    dayjs.utc(collab.startDate).isBefore(dayjs())
      ? dayjs().add(1, "day")
      : dayjs.utc(collab.startDate)
  );
  const [selectedSlots, setSelectedSlots] = useState<any[]>([]);
  const maxSlotsAllowed = previousBooking ? 1 : 4;

  const timeSlots = useMemo(() => {
    return generateTimeSlots(
      collab.bookingAvailability,
      collab.slotSizeMinutes
    );
  }, [collab]);

  const getSlotsOfDay = () => {
    if (!selectedDate) return [];
    const day = selectedDate.format("dddd").toLowerCase();
    return timeSlots.filter((slot) => slot.weekday?.toLowerCase() === day)[0];
  };

  const groupSlotsByTimeOfTheDay = () => {
    const slots = getSlotsOfDay();

    if (!slots) return [];
    //consider the slot value which is an array of hours string formatted as "HH:MM AM/PM"

    const morningSlots = slots.slots.filter(
      (slot: string) =>
        parseInt(slot.split(":")[0]) <= 12 && slot.includes("AM")
    );
    const afternoonSlots = slots.slots.filter(
      (slot: string) =>
        (parseInt(slot.split(":")[0]) === 12 ||
          parseInt(slot.split(":")[0]) < 6) &&
        slot.includes("PM")
    );
    const eveningSlots = slots.slots.filter(
      (slot: string) =>
        parseInt(slot.split(":")[0]) >= 6 &&
        parseInt(slot.split(":")[0]) < 12 &&
        slot.includes("PM")
    );
    return { morningSlots, afternoonSlots, eveningSlots };
  };

  const { morningSlots, afternoonSlots, eveningSlots }: any =
    groupSlotsByTimeOfTheDay();

  const months: { label: string; value: number }[] = useMemo(() => {
    const startDate = dayjs.utc(collab.startDate);
    const endDate = dayjs.utc(collab.endDate);

    const months = [];
    let currentDate = startDate.clone();
    // don't add past months
    if (currentDate.isBefore(dayjs())) {
      currentDate = dayjs();
    }
    while (currentDate.isBefore(endDate) || currentDate.isSame(endDate)) {
      months.push({
        label: currentDate.format("MMM"),
        value: currentDate.month(),
      });
      currentDate = currentDate.add(1, "month");
    }
    return months;
  }, [collab]);

  const compareDateWithSelectedDate = (date: Dayjs) => {
    return date.format("DD/MM/YYYY") === selectedDate.format("DD/MM/YYYY");
  };

  const isTimeSlotSelected = (slot: string) => {
    return selectedSlots.some(
      (item) => compareDateWithSelectedDate(item.date) && item.time === slot
    );
  };

  const disabledDate = (current: Dayjs) => {
    const startDate = dayjs.utc(collab.startDate);
    const endDate = dayjs.utc(collab.endDate).isBefore(dayjs().add(2, "week"))
      ? dayjs().add(2, "week")
      : dayjs.utc(collab.endDate);
    const availableWeekDays = timeSlots.map((slot) =>
      slot.weekday?.toLowerCase()
    );

    return (
      current.isBefore(dayjs()) ||
      current.isBefore(startDate) ||
      current.isAfter(endDate) ||
      !availableWeekDays.includes(current.format("dddd").toLowerCase())
    );
  };

  const onSlotClick = (slot: string) => {
    const isSlotSelected = selectedSlots.some(
      (item) => compareDateWithSelectedDate(item.date) && item.time === slot
    );

    if (isSlotSelected) {
      setSelectedSlots((prev) =>
        prev.filter(
          (item) =>
            !(compareDateWithSelectedDate(item.date) && item.time === slot)
        )
      );
    } else if (selectedSlots.length < maxSlotsAllowed) {
      setSelectedSlots((prev) => [...prev, { date: selectedDate, time: slot }]);
    } else {
      message.warning(`You can only select ${maxSlotsAllowed} slot(s)`);
    }
  };

  const onClckRemoveSlot = (index: number) => {
    setSelectedSlots((prev) => prev.filter((_, i) => i !== index));
  };

  const onClickProceed = () => {
    if (!loggedUser.dietaryPreferences) {
      navigate("/dietary-restrictions", {
        state: { selectedSlots, selectedDate, collab, page: "book", spotId },
      });
      return;
    }

    if (collab.ghostKitchen) {
      navigate("/book/address-delivery", {
        state: {
          selectedSlots,
          selectedDate,
          collab,
          spotId,
          previousBooking,
        },
      });
      return;
    }

    navigate("/book/collab-confirmation", {
      state: { selectedSlots, selectedDate, collab, spotId, previousBooking },
    });
  };

  return (
    <div className="book-detail">
      <div style={{ padding: 20 }}>
        <Row justify="space-between" style={{ alignItems: "center", gap: 20 }}>
          <LeftOutlined
            style={{ cursor: "pointer" }}
            onClick={() => navigate(-1)}
          />
          <Typography.Title level={3} style={{ margin: 0, flex: 1 }}>
            Book
          </Typography.Title>
          <span>{maxSlotsAllowed} slots to schedule</span>
        </Row>
        <Row style={{ height: 50, marginTop: 12 }}>
          <Col xs={24}>
            {selectedSlots.map((item, index) => (
              <span className="slot-info">
                {item.date.format("dddd DD/MM/YYYY")} {item.time}{" "}
                <CloseCircleOutlined onClick={() => onClckRemoveSlot(index)} />
              </span>
            ))}
          </Col>
        </Row>
        <Calendar
          fullscreen={false}
          value={selectedDate}
          onChange={(value) => setSelectedDate(value)}
          disabledDate={disabledDate}
          headerRender={({ value, onChange }) => (
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <span className="calendar-title">
                {value.format("MMMM YYYY")}
              </span>
              {months.length > 1 && (
                <Select
                  size="small"
                  popupMatchSelectWidth={false}
                  value={value.month()}
                  onChange={(newMonth) => {
                    const now = value.clone().month(newMonth);

                    if (disabledDate(now)) {
                      onChange(dayjs.utc(collab.startDate).month(newMonth));
                      return;
                    }
                    onChange(now);
                  }}
                  options={months}
                />
              )}
            </div>
          )}
        />
        <Row gutter={[8, 8]} style={{ marginBottom: 80 }}>
          {morningSlots?.length > 0 && (
            <Col xs={24} className="time-slot-group">
              <Row gutter={[8, 8]}>
                <Typography.Title
                  level={5}
                  style={{ display: "flex", gap: 10 }}
                >
                  <MorningIcon />
                  Morning
                </Typography.Title>
                {morningSlots.map((item: string) => (
                  <div
                    key={`${selectedDate.format("DD/MM/YYYY")}_${item}`}
                    className={`time-slot  ${
                      isTimeSlotSelected(item) ? "selected" : ""
                    }`}
                    onClick={() => onSlotClick(item)}
                  >
                    {item}
                  </div>
                ))}
              </Row>
            </Col>
          )}
          {afternoonSlots?.length > 0 && (
            <Col xs={24} className="time-slot-group">
              <Row gutter={[8, 8]}>
                <Typography.Title
                  level={5}
                  style={{ display: "flex", gap: 10 }}
                >
                  <AfternoonIcon />
                  Afternoon
                </Typography.Title>
                {afternoonSlots.map((item: string) => (
                  <div
                    key={`${selectedDate.format("DD/MM/YYYY")}_${item}`}
                    className={`time-slot  ${
                      isTimeSlotSelected(item) ? "selected" : ""
                    }`}
                    onClick={() => onSlotClick(item)}
                  >
                    {item}
                  </div>
                ))}
              </Row>
            </Col>
          )}

          {eveningSlots?.length > 0 && (
            <Col xs={24} className="time-slot-group">
              <Row gutter={[8, 8]}>
                <Typography.Title
                  level={5}
                  style={{ display: "flex", gap: 10 }}
                >
                  <EveningIcon />
                  Evening
                </Typography.Title>
                {eveningSlots.map((item: string) => (
                  <div
                    key={`${selectedDate.format("DD/MM/YYYY")}_${item}`}
                    className={`time-slot  ${
                      isTimeSlotSelected(item) ? "selected" : ""
                    }`}
                    onClick={() => onSlotClick(item)}
                  >
                    {item}
                  </div>
                ))}
              </Row>
            </Col>
          )}
        </Row>
      </div>
      <div
        className={`footer-collab ${selectedSlots.length > 0 && "show"}`}
        style={{
          width: isDesktop || isTablet ? 550 : 398,
        }}
      >
        <span>{`${selectedSlots.length}/${maxSlotsAllowed} slots`}</span>
        <Button type="primary" onClick={onClickProceed}>
          <strong>Proceed</strong>
        </Button>
      </div>
    </div>
  );
};

export default BookCollab;
