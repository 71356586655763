import { useState } from "react";
import Draggable, { DraggableData, DraggableEvent } from "react-draggable";
import "./SlideButton.scss";

interface SlideButtonProps {
  children?: React.ReactNode;
  content?: React.ReactNode;
  onSlide?: () => void;
}
const SlideButton: React.FC<SlideButtonProps> = (props) => {
  const { children, content, onSlide } = props;
  const [unlocked, setUnlocked] = useState<boolean>(false);
  const [position, setPosition] = useState<{ x: number; y: number }>({
    x: 0,
    y: 0,
  });

  const handleStop = (e: DraggableEvent, data: DraggableData) => {
    if (data.x >= 250) {
      // setUnlocked(true);
      if (onSlide) onSlide();
    } else {
      setPosition({ x: 0, y: 0 });
    }
  };

  const handleDrag = (e: DraggableEvent, data: DraggableData) => {
    setPosition({ x: data.x, y: 0 });
  };

  return (
    <div className="slide-button container">
      {unlocked ? (
        <div className="unlocked" onClick={() => setUnlocked(false)}></div>
      ) : (
        <div className="slider">
          <Draggable
            axis="x"
            bounds="parent"
            position={position}
            onStop={handleStop}
            onDrag={handleDrag}
          >
            <div className="handle">{children}</div>
          </Draggable>
          {content}
        </div>
      )}
    </div>
  );
};

export default SlideButton;
