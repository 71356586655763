import { Typography } from "antd";
import { ReactComponent as LogoutIcon } from "assets/images/logout-icon.svg";
import { logout } from "helpers/auth-functions";
import { useEffect } from "react";
import { isMobile } from "react-device-detect";
import { useRouteError } from "react-router-dom";
import * as Sentry from "@sentry/react";

const Error: React.FC = () => {
  const error = useRouteError();

  const onLogoutClick = () => {
    logout();
  };

  useEffect(() => {
    if (error) {
      Sentry.withScope((scope) => {
        const errorDetails = {
          message: "Error caught in ErrorBoundary",
        };

        scope.setExtras(errorDetails);
        Sentry.captureException(error);
      });
    }
  }, [error]);

  return (
    <div style={{ textAlign: "center" }}>
      {isMobile && (
        <div
          style={{
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
          }}
        >
          <LogoutIcon
            style={{ width: 20, height: 20 }}
            onClick={() => onLogoutClick()}
          />
        </div>
      )}
      <Typography.Title level={3}>
        Sorry we encountered an enforceable error.{" "}
      </Typography.Title>

      <Typography.Text style={{ fontSize: 16 }}>
        Our engineers are already notified and working on the fix.
      </Typography.Text>
    </div>
  );
};

export default Error;
