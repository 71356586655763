export const formatNumberKMBT = (number: number) => {
  if (!number && number !== 0) return "-";
  if (number > 999 && number <= 999999) {
    return (number / 1000).toFixed(1) + "K";
  } else if (number > 999999 && number <= 999999999) {
    return (number / 1000000).toFixed(1) + "M";
  } else if (number > 999999999 && number <= 999999999999) {
    return (number / 1000000000).toFixed(1) + "B";
  } else if (number > 999999999999) {
    return (number / 1000000000000).toFixed(1) + "T";
  } else {
    return number;
  }
};
