import { CheckOutlined, LeftOutlined, PlusOutlined } from "@ant-design/icons";
import { Col, Row, Typography } from "antd";
import { UserProfile } from "interfaces/user-profile";
import { debounce } from "lodash";
import { useCallback, useEffect, useRef, useState } from "react";
import { isDesktop } from "react-device-detect";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getLoggedUserInfo } from "redux-context/user";
import {
  getFoodieCategories,
  updateUserInfoCategory,
} from "services/SauceService";

interface Props {
  // Define your component props here
}

const BestCategories: React.FC<Props> = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<any>();
  const { loggedUser }: { loggedUser: UserProfile } = useSelector(
    (state: any) => state.user
  );
  const [categories, setCategories] = useState<string[]>([]);
  const [preferedCategories, setPreferedCategories] = useState<string[]>([]);

  const getCategories = useCallback(async () => {
    const response: any = await getFoodieCategories();
    setCategories(response);
  }, []);

  const debouncedSave = useRef(
    debounce(async (preferedCategories: string[]) => {
      await updateUserInfoCategory({
        categories: preferedCategories,
      });
      dispatch(getLoggedUserInfo());
    }, 2000)
  ).current;

  const onClickCategory = (category: string) => {
    if (preferedCategories.includes(category)) {
      const categories = preferedCategories.filter((item) => item !== category);
      debouncedSave(categories);
      setPreferedCategories(categories);
    } else {
      const categories = [...preferedCategories, category];
      debouncedSave(categories);
      setPreferedCategories(categories);
    }
  };

  useEffect(() => {
    getCategories();
  }, [getCategories]);

  useEffect(() => {
    setPreferedCategories(loggedUser.categories || []);
  }, [loggedUser]);

  return (
    <div className="categories profile" style={{ height: "100dvh" }}>
      <div style={{ position: "sticky", height: 85 }}>
        <Row justify="space-between" style={{ alignItems: "center" }}>
          <div style={{ display: "flex" }}>
            <LeftOutlined
              style={{ cursor: "pointer" }}
              onClick={() => navigate(-1)}
            />
            <Typography.Title level={3} style={{ margin: "auto 20px" }}>
              Best categories
            </Typography.Title>
          </div>
        </Row>
        <Row>
          <span className="general-sub-title">
            Choose the categories that identify you as a creator.
          </span>
        </Row>
      </div>

      <Row
        gutter={[12, 20]}
        justify="center"
        style={{
          paddingTop: 20,
          overflow: "auto",
          height: isDesktop ? "calc(100vh - 100px)" : "calc(100vh - 200px)",
        }}
      >
        {categories?.map((item) => {
          const selected = preferedCategories.includes(item);

          return (
            <Col key={`CATEGORY_${item}`}>
              <div
                className={`category ${selected ? "selected" : ""}`}
                onClick={() => onClickCategory(item)}
              >
                <span className="item">
                  {selected ? <PlusOutlined /> : <CheckOutlined />}
                  {item}
                </span>
              </div>
            </Col>
          );
        })}
      </Row>
    </div>
  );
};

export default BestCategories;
