import { LeftOutlined, LoadingOutlined } from "@ant-design/icons";
import { Button, Row, Typography } from "antd";
import { Booking } from "interfaces/booking";
import { useLocation, useNavigate } from "react-router-dom";
import "./Confirm.scss";
import { useState } from "react";
import { isDesktop, isTablet } from "react-device-detect";
import { setBackgroundColor } from "redux-context/page";
import { useDispatch } from "react-redux";
import { confirmBooking } from "services/SauceService";

const Confirm: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const { state } = location || {};
  const { collab }: { collab: Booking } = state || {};

  const onClickConfirm = async () => {
    setLoading(true);

    const { collabId, bookingId } = collab;
    try {
      await confirmBooking({ collabId, bookingId });
      navigate("/confirmed", {
        state: { collab },
      });
      dispatch(setBackgroundColor("#e2f2dc"));
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const textByDeliverable = (deliverable: string) => {
    if (deliverable?.toLowerCase().includes("instagram reel"))
      return [
        "Add real with offer",
        ` Tag @${collab.instagramAccount} as a collaborator`,
        "Tag @mustard.love as a collaborator",
      ];
    //Instagram Story
    if (deliverable?.toLowerCase().includes("instagram story"))
      return [
        "Add story with offer",
        `Tag @${collab.instagramAccount}`,
        "Tag @mustard.love",
      ];
    //Tiktok video
    if (deliverable?.toLowerCase().includes("tiktok video"))
      return [
        "Add video with offer",
        `Tag @${collab.tiktokAccount}`,
        "Tag @mustard.love",
      ];
    return [];
  };

  return (
    <div className="page-full-screen confirm">
      <Row style={{ alignItems: "center", gap: 12 }}>
        <LeftOutlined
          style={{ cursor: "pointer" }}
          onClick={() => navigate(-1)}
        />
        <Typography.Title level={3} style={{ margin: 0 }}>
          Check the Brief
        </Typography.Title>

        <div className="briefing" style={{ marginBottom: 80 }}>
          <span className="title">Brief:</span>
          <Typography.Paragraph>{collab.brief}</Typography.Paragraph>

          <span className="title">Deliverables:</span>
          {collab.deliverables.map((deliverable, index) => (
            <div key={`DELIVERABLES_${index}`}>
              <span className="subtitle">{deliverable}</span>
              <ul>
                {textByDeliverable(deliverable).map((text, index) => (
                  <li
                    key={`DELIVERABLES_TEXT_${index}`}
                    className="circle checkmark"
                  >
                    {text}
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </Row>
      <Button
        type="primary"
        className={
          isTablet || isDesktop ? "confirm-button" : "floating-confirm-button"
        }
        onClick={onClickConfirm}
      >
        {loading ? <LoadingOutlined spin style={{ fontSize: 24 }} /> : "Got it"}
      </Button>
    </div>
  );
};

export default Confirm;
