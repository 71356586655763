import { Button, Typography } from "antd";
import { useNavigate } from "react-router-dom";
import BookCancelledBg from "assets/images/cancelled-icon.png";

const BookCancelled: React.FC = () => {
  const navigate = useNavigate();
  return (
    <div className="book-confirmation red">
      <div>
        <Typography.Title level={3} style={{ margin: "36px 0 16px 0" }}>
          Collab canceled
        </Typography.Title>

        <Typography.Text style={{ margin: 0 }}>
          You will no longer see it in your collabs
        </Typography.Text>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <img
            src={BookCancelledBg}
            alt="collab"
            style={{ width: "50%", marginTop: 80 }}
          />
        </div>
      </div>
      <div className="buttons">
        <Button type="primary" onClick={() => navigate("/collabs")}>
          Back to Collabs
        </Button>
      </div>
    </div>
  );
};

export default BookCancelled;
