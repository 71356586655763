import { Layout } from "antd";

const { Content } = Layout;

const LoginLayout: React.FC<any> = ({ children }) => {
  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Content>{children}</Content>
    </Layout>
  );
};

export default LoginLayout;
