import { HomeData } from "interfaces/home-data";
import { getUserInbox } from "services/SauceService";

export async function homeLoader() {
  const foodieId = localStorage.getItem("foodieId");

  const response = await getUserInbox({
    foodieId,
  });

  const { today, tomorrow, contentDue, upcoming, pending } =
    ((response as any) || {}) as HomeData;

  // if (today.length === 0) {
  //   today.push(contentDue[3]);
  // }
  // if (tomorrow.length === 0) {
  //   tomorrow.push(contentDue[4]);
  // }
  // if (upcoming.length === 0) {
  //   upcoming.push(...contentDue);
  // }
  return { today, tomorrow, contentDue, upcoming, pending, foodieId };
}
