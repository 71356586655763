export function generateTimeSlots(
  input: any[],
  slotSizeMinutes: number = 60
): any[] {
  const result = input
    .filter((value) => value.open !== false)
    .map((day) => {
      // Determine the property names used in the input
      const dayOfWeekProp = day.dayOfWeek ? "dayOfWeek" : "day_of_week";
      const startTimeProp = day.startTime ? "startTime" : "start_time";
      const endTimeProp = day.endTime ? "endTime" : "end_time";

      if (
        day[startTimeProp] &&
        (day[startTimeProp].toLocaleLowerCase().includes("am") ||
          day[startTimeProp].toLocaleLowerCase().includes("pm"))
      ) {
        const startTime = day[startTimeProp];
        const endTime = day[endTimeProp];

        // Convert to 24-hour format and calculate start and end times
        const parseTime = (timeStr: string) => {
          const [time, modifier] = timeStr.split(" ");
          let [hours, minutes] = time.split(":").map(Number);

          if (hours === 12) {
            hours = modifier?.toLowerCase() === "am" ? 0 : 12; // Convert 12 AM to 00 hours and 12 PM to 12
          } else {
            hours = modifier?.toLowerCase() === "pm" ? hours + 12 : hours;
          }

          return { hours, minutes };
        };

        const { hours: startHours, minutes: startMinutes } =
          parseTime(startTime);
        const { hours: endHours, minutes: endMinutes } = parseTime(endTime);

        let currentTime = new Date();
        currentTime.setHours(startHours, startMinutes, 0, 0);

        const endTimeDate = new Date();
        endTimeDate.setHours(endHours, endMinutes, 0, 0);

        // Ensure correct calculation over midnight
        if (endTimeDate <= currentTime) {
          endTimeDate.setDate(endTimeDate.getDate() + 1); // move end time to the next day
        }

        const slots = [];
        const incrementMinutes =
          slotSizeMinutes && slotSizeMinutes > 0 ? slotSizeMinutes : 60; // Increment by 1 hour change to get from collab if setted

        while (currentTime < endTimeDate) {
          const period = currentTime.getHours() >= 12 ? "PM" : "AM";
          const hour = currentTime.getHours() % 12 || 12; // Convert 24-hour time to 12-hour format
          const minute = String(currentTime.getMinutes()).padStart(2, "0");

          slots.push(`${hour}:${minute} ${period}`);
          currentTime.setMinutes(currentTime.getMinutes() + incrementMinutes);
        }

        return {
          weekday: day[dayOfWeekProp].toUpperCase(),
          slots: slots,
        };
      } else {
        // Parse the start and end times
        const [startHour, startMinutes] = day[startTimeProp]
          .split(":")
          .map(Number);
        const [endHour, endMinutes] = day[endTimeProp].split(":").map(Number);

        let currentTime = new Date();
        currentTime.setHours(startHour, startMinutes, 0, 0);

        const endTime = new Date();
        endTime.setHours(endHour, endMinutes, 0, 0);

        const slots = [];
        const incrementMinutes = 90; // 1 hour 30 minutes

        // Generate time slots
        while (currentTime <= endTime) {
          const period = currentTime.getHours() >= 12 ? "PM" : "AM";
          const hour = currentTime.getHours() % 12 || 12; // Convert to 12-hour format
          const minute = String(currentTime.getMinutes()).padStart(2, "0");

          slots.push(`${hour}:${minute} ${period}`);

          currentTime.setMinutes(currentTime.getMinutes() + incrementMinutes);
        }
        return {
          weekday: day[dayOfWeekProp],
          slots: slots,
        };
      }
    });

  return result;
}
