import { useCallback, useEffect, useMemo, useState } from "react";
import "./FindCollabs.scss";
import {
  getAvailableCollabsByFilter,
  getAvailableCollabsCities,
  retrieveClosestLocation,
} from "services/SauceService";
import { Row, Select, Spin, Tag, Tooltip, Typography } from "antd";
import { Collab } from "interfaces/collab";
import {
  LoadingOutlined,
  QuestionCircleOutlined,
  RightOutlined,
} from "@ant-design/icons";
import { ReactComponent as CalendarIcon } from "assets/images/calendar-icon.svg";
import dayjs from "dayjs";
import pinIcon from "assets/images/pin-icon.png";
import { useNavigate } from "react-router-dom";
import { isDesktop, isTablet } from "react-device-detect";
import { useDispatch, useSelector } from "react-redux";
import { FindLocationCollabs, setLocation } from "redux-context/user";

interface City {
  cityName: string;
  latitude: string;
  longitude: string;
}

interface Coordinates {
  lat: number;
  lng: number;
}

const getLocationFromStorage = () => {
  const rawLocation = localStorage.getItem("userLocation");
  const location =
    rawLocation && rawLocation.trim().length > 0
      ? JSON.parse(rawLocation)
      : null;

  return location;
};

const FindCollabs: React.FC = () => {
  const { findCollabslocation }: { findCollabslocation: FindLocationCollabs } =
    useSelector((state: any) => state.user);

  const dispatch = useDispatch<any>();
  const [coordinates, setCoordinates] = useState<Coordinates>(
    findCollabslocation?.coordinates
  );
  const [cities, setCities] = useState<City[]>([]);
  const [selectedCity, setSelectedCity] = useState<string>(
    findCollabslocation?.city
  );
  const [collabs, setCollabs] = useState<Collab[]>([]);
  const [cardHeight, setCardHeight] = useState<number>(350);
  const [loading, setLoading] = useState<boolean>(true);
  const [selectedCollab, setSelectedCollab] = useState<Collab>();

  const [userLocationFromStorage] = useState<Coordinates>(
    getLocationFromStorage()
  );

  // const [permission, setPermission] = useState<
  //   "not-asked" | "granted" | "not-granted"
  // >("not-asked");
  const navigate = useNavigate();

  const getCities = useCallback(async () => {
    const response: any = await getAvailableCollabsCities();
    setCities(response);
    return response;
  }, []);

  const getCollabs = useCallback(async () => {
    if (coordinates) {
      setLoading(true);
      const params = {
        latitude: coordinates?.lat,
        longitude: coordinates?.lng,
        maxMilesDistance: 50,
      };

      const response: any = await getAvailableCollabsByFilter(params);
      const list = response ? [...response] : [];
      list.sort((a: Collab, b: Collab) => {
        if (a.isQualified === b.isQualified) {
          return new Date(a.endDate).getTime() - new Date(b.endDate).getTime();
        }
        return a.isQualified ? -1 : 1;
      });
      setCollabs(list);
      setLoading(false);
    }
  }, [coordinates]);

  const getNearestCity = useCallback(
    async (coordinates: any) => {
      if (findCollabslocation?.city) {
        setSelectedCity(findCollabslocation.city);
        setCoordinates(findCollabslocation.coordinates);
        return;
      }
      const response: any = await retrieveClosestLocation(coordinates);
      const city = cities.find
        ? cities.find((city) => city.cityName === response.cityName)
        : null;

      if (city) {
        setSelectedCity(city.cityName);
        setCoordinates({
          lat: parseFloat(city.latitude),
          lng: parseFloat(city.longitude),
        });
      } else {
        if (cities.length) {
          setSelectedCity(cities[0].cityName);
          setCoordinates({
            lat: parseFloat(cities[0].latitude),
            lng: parseFloat(cities[0].longitude),
          });
        }
      }
    },
    [findCollabslocation, cities]
  );

  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    if (isTablet || isDesktop) return;
    const target = event.target as HTMLDivElement;

    if (target.scrollTop > 0) {
      setCardHeight(300);
      // console.log("scrolling up");
    } else {
      setCardHeight(400);
      // console.log("scrolling down");
    }
  };

  const cityOptions = useMemo(() => {
    return cities.map((city) => ({
      value: city.cityName,
      label: city.cityName,
      ...city,
    }));
  }, [cities]);

  const onChangeCity = (city: string, option: any) => {
    setSelectedCity(city);
    setCoordinates({
      lat: parseFloat(option.latitude),
      lng: parseFloat(option.longitude),
    });
    dispatch(
      setLocation({
        city,
        coordinates: { lat: option.latitude, lng: option.longitude },
      })
    );
  };

  const onClickCollab = (collab: Collab) => {
    const { spotId, isQualified } = collab;
    if (isQualified) {
      navigate(`/collabs/${collab.collabId}`, { state: { spotId } });
    }
  };

  useEffect(() => {
    if (!userLocationFromStorage) {
      // if (navigator.geolocation) {
      //   const options = {
      //     enableHighAccuracy: true,
      //     timeout: 10000,
      //     maximumAge: 0,
      //   };
      //   navigator.geolocation.getCurrentPosition(
      //     (position) => {
      //       getNearestCity({
      //         latitude: position.coords.latitude,
      //         longitude: position.coords.longitude,
      //       });
      //     },
      //     (error) => {
      //       console.log(error);
      //       getNearestCity({});
      //       // setError(error.message);
      //     },
      //     options
      //   );
      //   return;
      // }

      getNearestCity({});
    } else {
      getNearestCity(userLocationFromStorage);
    }
  }, [getNearestCity, userLocationFromStorage]);

  useEffect(() => {
    getCities();
  }, [getCities]);

  useEffect(() => {
    getCollabs();
  }, [getCollabs]);

  useEffect(() => {
    async function initMap() {
      const mapOptions: google.maps.MapOptions = {
        center: coordinates,
        zoom: 10,
        disableDefaultUI: true,
        mapId: "f1b7b3b3b1b7b3b3",
      };

      // Criar o mapa dentro do contêiner com ID 'map'
      const map = new google.maps.Map(
        document.getElementById("map") as HTMLElement,
        mapOptions
      );
      const markerContent = document.createElement("div");
      markerContent.style.width = "15px"; // Largura do círculo
      markerContent.style.height = "15px"; // Altura do círculo
      markerContent.style.backgroundColor = "#0000FF"; // Cor de preenchimento
      markerContent.style.border = "1px solid #FFFFFF"; // Cor da borda
      markerContent.style.borderRadius = "50%"; // Torna o div um círculo
      markerContent.style.opacity = "1.0";
      markerContent.style.position = "absolute";
      markerContent.style.transform = "translate(-50%, -50%)";

      new google.maps.marker.AdvancedMarkerElement({
        position: coordinates,
        map: map,
        content: markerContent,
      });

      // Adiciona o primeiro círculo maior
      var circle1 = new google.maps.Circle({
        strokeColor: "#FFFFFF",
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: "#0000FF",
        fillOpacity: 0.35,
        map: map,
        center: coordinates,
        radius: 50 * 4,
      });

      var circle2 = new google.maps.Circle({
        strokeColor: "#FFFFFF",
        strokeOpacity: 0.5,
        strokeWeight: 2,
        fillColor: "#0000FF",
        fillOpacity: 0.2,
        map: map,
        center: coordinates,
        radius: 500 * 4,
      });

      google.maps.event.addListener(map, "zoom_changed", function () {
        var newZoom = map.getZoom();
        if ((!newZoom && newZoom !== 0) || newZoom < 10) return;

        var baseRadius = 100; // Define um raio base grande
        var zoomFactor = Math.pow(2, 15 - newZoom); // Inverte o fator exponencial baseado no zoom

        circle1.setRadius(baseRadius * zoomFactor * 0.5); // Ajusta o raio base pelo fator de zoom invertido
        circle2.setRadius(baseRadius * zoomFactor);
      });

      collabs.forEach(async (collab) => {
        if (!collab.venue.addressCoordinates) return;

        const pinImg = document.createElement("img");
        pinImg.style.width = "30px"; // Largura do círculo
        pinImg.style.height = "30px"; // Altura do círculo
        pinImg.src = pinIcon;

        const marker = new google.maps.marker.AdvancedMarkerElement({
          map,
          position: {
            lat: collab.venue.addressCoordinates.coordinates[1],
            lng: collab.venue.addressCoordinates.coordinates[0],
          },
          content: pinImg,
        });

        // Adicionar evento de clique no pin
        google.maps.event.addListener(marker, "click", () => {
          setSelectedCollab(collab); // Define a collab selecionada no estado
        });
      });
    }

    initMap();
  }, [coordinates, collabs]);

  useEffect(() => {
    if (selectedCollab) {
      // Seleciona o elemento com id do card correspondente e usa scrollIntoView
      // {`collab-card-${collab.collabId}`}
      const element = document.getElementById(
        `collab-card-${selectedCollab.collabId}`
      );
      element?.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }, [selectedCollab]);

  return (
    <div className="find-collabs">
      <Select
        value={selectedCity}
        options={cityOptions}
        style={{
          position: "absolute",
          zIndex: 1,
          width: 200,
          left: "50%",
          transform: "translate(-50%, 0)",
          top: 20,
        }}
        onChange={onChangeCity}
        showSearch={!isDesktop}
      />

      <div className="maps-container" style={{ height: cardHeight }}>
        <div id="map" style={{ width: "100%", height: "400px" }} />
      </div>
      <div className="card-collabs" onScroll={handleScroll}>
        <Typography.Title level={3} style={{ marginTop: 0 }}>
          Find collabs
        </Typography.Title>
        <div className="collabs">
          {loading ? (
            <Spin
              indicator={<LoadingOutlined style={{ fontSize: 72 }} spin />}
            />
          ) : collabs.length > 0 ? (
            collabs.map((collab) => (
              <div
                key={collab.collabId}
                id={`collab-card-${collab.collabId}`}
                className={`collab ${
                  selectedCollab?.collabId === collab.collabId ? "selected" : ""
                }`}
                onClick={() => onClickCollab(collab)}
                style={{ cursor: collab.isQualified ? "pointer" : "default" }}
              >
                <div style={{ flex: 1 }}>
                  <Row style={{ justifyContent: "space-between" }}>
                    <span
                      className="highlight ellipsis"
                      style={{ maxWidth: 300 }}
                    >
                      {collab.venue.name}
                    </span>
                    {collab.isQualified && (
                      <RightOutlined style={{ fontSize: 24 }} />
                    )}
                  </Row>
                  <Row style={{ margin: "6px 0" }}>
                    <span className="text" style={{ color: "#666666" }}>
                      {Number(collab.distanceInMiles).toFixed(0)}m
                    </span>{" "}
                    {" ∘ "}
                    <span
                      className="ellipsis"
                      style={{ color: "#1D2023", maxWidth: 230 }}
                    >
                      {collab.venue.address}
                    </span>
                  </Row>
                  <Row style={{ color: "#666666", gap: 4 }}>
                    <CalendarIcon />
                    <span>
                      from {dayjs.utc(collab.startDate).format("MMM DD")}
                    </span>{" "}
                    {" ∘ "}
                    <span>
                      {collab.totalSpotTaken}/{collab.totalSpotNeeded} spots
                      left
                    </span>
                    {collab.totalApplications > 0 && (
                      <>
                        {" ∘ "}
                        <span className="applicants">
                          {collab.totalApplications} Applicants
                        </span>
                      </>
                    )}
                  </Row>
                  {collab.isQualified ? (
                    <Row style={{ gap: 8, marginTop: 12 }}>
                      {collab.guestAllowed}
                      <div className="box-perk">
                        🍴
                        <div className="value">
                          <span>Guests</span>
                          <span>
                            <b>+1</b>
                          </span>
                        </div>
                      </div>
                      {collab.foodCompAmount > 0 && (
                        <div className="box-perk">
                          🍔
                          <div className="value">
                            <span>Food</span>
                            <span>
                              <b>${collab.foodCompAmount}</b>
                            </span>
                          </div>
                        </div>
                      )}
                      {collab.cashCompAmount > 0 && (
                        <div className="box-perk">
                          💸
                          <div className="value">
                            <span>Comp</span>
                            <span>
                              <b>${collab.cashCompAmount}</b>
                            </span>
                          </div>
                        </div>
                      )}
                    </Row>
                  ) : (
                    <Row style={{ margin: "6px 0" }}>
                      <div
                        className="box-perk"
                        style={{ backgroundColor: "#ececec", padding: 10 }}
                      >
                        <div className="value">
                          <span
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: 6,
                            }}
                          >
                            Not qualified yet{" "}
                            <Tooltip
                              title={`This collaboration requires a
                            minimum of ${collab.minimumFollowers} followers.
                            Continue to grow your account to qualify for more
                            collaborations.`}
                            >
                              <QuestionCircleOutlined />
                            </Tooltip>
                          </span>
                        </div>
                      </div>
                    </Row>
                  )}
                  {collab.ghostKitchen && (
                    <>
                      <div className="line" style={{ margin: "12px 0" }} />
                      <Row align="middle">
                        <Tag className="ghost-kitchen" color="black">
                          Ghost kitchen
                        </Tag>
                        <span>Delivery included</span>
                      </Row>
                    </>
                  )}
                </div>
              </div>
            ))
          ) : (
            <div style={{ fontSize: 20, textAlign: "center" }}>
              No collabs found
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default FindCollabs;
