export enum BookingStatus {
  PendingConfirmation = 0,
  BookingConfirmed = 1,
  RequestedChange = 2,
  RequestedCancellation = 3,
  WaitingContent = 4, // esse aqui que valida se ja fez checkin
  PendingContentReview = 5,
  Complete = 6,
  Rejected = 7,
  Canceled = 8,
  PendingRestaurantApproval = 9,
  Expired = 10,
}
