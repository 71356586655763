import { LeftOutlined } from "@ant-design/icons";
import {
  Avatar,
  Form,
  GetProp,
  Input,
  message,
  Row,
  Typography,
  Upload,
  UploadProps,
} from "antd";
import { RcFile } from "antd/es/upload";
import { UserProfile } from "interfaces/user-profile";
import { debounce } from "lodash";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getLoggedUserInfo } from "redux-context/user";
import { updateUserInfoGeneral, uploadLogo } from "services/SauceService";

interface PersonalInfoProps {
  // Add any props you need here
}

type FileType = Parameters<GetProp<UploadProps, "beforeUpload">>[0];
const getBase64FromFileType = (
  img?: RcFile,
  callback?: (url: string) => void
) => {
  if (img) {
    const reader = new FileReader();
    reader.addEventListener("load", () => {
      if (callback) {
        callback(reader.result as string);
      }
    });
    reader.readAsDataURL(img);
  }
};

const PersonalInfo: React.FC<PersonalInfoProps> = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const dispatch = useDispatch<any>();
  const { loggedUser }: { loggedUser: UserProfile } = useSelector(
    (state: any) => state.user
  );
  const [imageUrl, setImageUrl] = useState<string>();

  const handleUpload = async (file: FileType) => {
    const formData = new FormData();
    formData.append("logo", file);
    const params = { logoProfile: file };
    try {
      await uploadLogo(params);
    } catch (error) {
      console.error(error);
      message.error("Failed to upload logo");
    }
  };

  const debouncedSave = useRef(
    debounce(async () => {
      const values = form.getFieldsValue(true);
      await updateUserInfoGeneral(values);

      dispatch(getLoggedUserInfo());
    }, 2000)
  ).current;

  const beforeUpload = (file: FileType) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
    }
    return false;
  };

  const handleImageChange: UploadProps["onChange"] = (info) => {
    const file = info.fileList[0];
    if (file.originFileObj) handleUpload(file.originFileObj);
    getBase64FromFileType(file.originFileObj, (url) => {
      setImageUrl(url);
    });
  };

  useEffect(() => {
    form.resetFields();
  }, [form, loggedUser]);

  useEffect(() => {
    setImageUrl(loggedUser?.avatarUrl);
  }, [loggedUser]);

  return (
    <div className="personal-info profile">
      <Row
        justify="space-between"
        style={{ alignItems: "center", marginTop: 20 }}
      >
        <div style={{ display: "flex" }}>
          <LeftOutlined
            style={{ cursor: "pointer" }}
            onClick={() => navigate(-1)}
          />
          <Typography.Title level={3} style={{ margin: "auto 20px" }}>
            Personal info
          </Typography.Title>
        </div>
      </Row>
      <Row justify="center">
        <Upload
          showUploadList={false}
          beforeUpload={beforeUpload}
          onChange={handleImageChange}
          maxCount={1}
        >
          {imageUrl ? (
            <div className="upload-avatar">
              <div className="edit-overlay">
                <Avatar src={imageUrl} size={100} />
                <span className="edit-text">Edit</span>
              </div>
            </div>
          ) : (
            <div className="upload">
              <div className="edit-overlay">
                {/* <Avatar src={""} size={100} /> */}
                <span
                  style={{
                    position: "absolute",
                    fontSize: 30,
                    left: "50%",
                    top: "50%",
                    transform: "translate(-50%, -50%)",
                  }}
                >
                  🍎
                </span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="100"
                  height="100"
                  viewBox="0 0 100 100"
                  fill="none"
                >
                  <circle
                    cx="50"
                    cy="50"
                    r="42"
                    stroke="url(#paint0_linear_192_9575)"
                    strokeWidth="6"
                    strokeLinecap="round"
                  />
                  <defs>
                    <linearGradient
                      id="paint0_linear_192_9575"
                      x1="6"
                      y1="64.5"
                      x2="85.8841"
                      y2="64.5"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#1F3FFF" />
                      <stop offset="0.495007" stopColor="#FF065C" />
                      <stop offset="1" stopColor="#FFAC39" />
                    </linearGradient>
                  </defs>
                </svg>
                <span className="edit-text">Edit</span>
              </div>
            </div>
          )}
        </Upload>
      </Row>
      <Form
        form={form}
        layout="vertical"
        initialValues={loggedUser}
        onChange={() => {
          debouncedSave();
        }}
      >
        <Form.Item name="name" label="Full name">
          <Input />
        </Form.Item>
        <Form.Item name="bio" label="Bio">
          <Input.TextArea rows={5} />
        </Form.Item>
      </Form>
    </div>
  );
};

export default PersonalInfo;
