import {
  ClockCircleOutlined,
  EllipsisOutlined,
  LeftOutlined,
} from "@ant-design/icons";
import { Button, Dropdown, Row, Tag, Typography } from "antd";
import dayjs from "dayjs";
import { useLoaderData, useNavigate } from "react-router-dom";
import { ReactComponent as LocationIcon } from "assets/images/location-icon.svg";
import { getGoogleMapsUrl } from "helpers/google-maps-utils";
import { Booking } from "interfaces/booking";
import "../collabs/Collabs.scss";
import { BookingStatus } from "enums/BookingStatus";
import { SlideButton } from "components";
import { calculateTimeLeft } from "pages/collabs/Collabs";
import ConfirmCircle from "assets/images/confirm-circle.png";
import CheckinCircle from "assets/images/checkin-circle.png";
import { checkIn } from "services/SauceService";
import { isMobile } from "react-device-detect";
import { onClickCopy } from "helpers/copy-helper";
import { ReactComponent as CopyIcon } from "assets/images/copy-icon.svg";
// const { Countdown } = Statistic;

const labelByStatys = (status: BookingStatus, applicationDate: string) => {
  switch (status) {
    case BookingStatus.PendingConfirmation:
    case BookingStatus.RequestedChange:
    case BookingStatus.RequestedCancellation:
    case BookingStatus.PendingRestaurantApproval:
      return "Pending";
    case BookingStatus.BookingConfirmed:
      const currentDateTime = dayjs();
      if (dayjs(applicationDate).isBefore(currentDateTime)) {
        return "Active";
      } else {
        return "Upcoming";
      }
    case BookingStatus.WaitingContent:
    case BookingStatus.PendingContentReview:
      return "Active";
    case BookingStatus.Complete:
      return "Completed";
    case BookingStatus.Rejected:
    case BookingStatus.Canceled:
    case BookingStatus.Expired:
      return "Canceled";
    default:
      return "Unknown";
  }
};

const colorTagByStatus = (status: BookingStatus) => {
  switch (status) {
    case BookingStatus.PendingConfirmation:
    case BookingStatus.RequestedChange:
    case BookingStatus.RequestedCancellation:
    case BookingStatus.PendingRestaurantApproval:
    case BookingStatus.BookingConfirmed:
    case BookingStatus.WaitingContent:
    case BookingStatus.PendingContentReview:
      return "gray";
    case BookingStatus.Complete:
      return "green";
    case BookingStatus.Rejected:
    case BookingStatus.Canceled:
    case BookingStatus.Expired:
      return "error";
    default:
      return "gray";
  }
};

// BookingConfirmed: 1, =>
//   if not day before, disabled,
// from the day before until checked in show “I Confirm”
const canConfirmAttendance = (
  status: BookingStatus,
  applicationDate: string,
  attendanceConfirmation: boolean
) => {
  const currentDateTime = dayjs();
  const targetDate = dayjs(applicationDate);
  const diff = targetDate.diff(currentDateTime, "day");

  if (
    status === BookingStatus.BookingConfirmed &&
    diff === 0 &&
    !attendanceConfirmation
  ) {
    return true;
  }
  return false;
};

const BookDetail: React.FC = () => {
  const { booking } = useLoaderData() as { booking: Booking };
  const navigate = useNavigate();
  console.log("booking", booking);
  const isToday = dayjs(booking.applicationDate?.date || 0).isSame(
    dayjs(),
    "day"
  );
  const isPast = dayjs(booking.applicationDate?.date || 0).isBefore(
    dayjs(),
    "day"
  );
  const laDateCancelationTime = dayjs(booking.applicationDate?.date || 0)
    .tz("America/Los_Angeles")
    .set("hour", 18)
    .set("minute", 0)
    .toISOString();

  const onClickChangeDateTime = async () => {
    navigate(`/book/reschedule`, {
      state: { booking },
    });
  };

  const onClickCancelCollab = async () => {
    navigate("/book/cancel", { state: { booking } });
  };

  const onConfirm = () => {
    navigate("/confirm", { state: { collab: booking } });
  };

  const onCheckin = async () => {
    await checkIn({
      bookingId: booking.bookingId,
    });
    navigate("/welcome", { state: { booking } });
  };

  const items = () => {
    if (!booking.isReschedule) {
      return [
        {
          label: "Change Date/Time",
          className: "regular-menu-item",
          key: "change-date-time",
          onClick: onClickChangeDateTime,
        },
        {
          type: "divider",
        },
        {
          label: "Cancel Collab",
          className: "danger-menu-item",
          key: "cancel-collab",
          onClick: onClickCancelCollab,
        },
      ];
    }

    return [
      {
        label: "Change Date/Time",
        className: "regular-menu-item",
        key: "change-date-time",
        onClick: onClickChangeDateTime,
      },
    ];
    // return menu;
  };

  const Buttons = () => {
    return (
      <>
        {canConfirmAttendance(
          booking.status,
          booking.applicationDate?.date,
          booking.attendanceConfirmation
        ) && (
          <SlideButton
            onSlide={() => onConfirm()}
            content={
              <div className="slider-content">
                <span className="main">I confirm</span>
                <span>
                  {calculateTimeLeft({ date: laDateCancelationTime })}
                </span>
                {/* <Countdown
              value={dayjs(booking.applicationDate?.date)
                .tz("America/Los_Angeles")
                .set("hour", 18)
                .set("minute", 0)
                .toISOString()}
              format="HH:mm:ss"
              // prefix={<TimerIcon />}
              className="countdown"
            /> */}
                {/* <span>{`only ${collab?.numberOfFoodiesRemaining} spots left`}</span> */}
              </div>
            }
          >
            <img src={ConfirmCircle} alt="" draggable="false" />
          </SlideButton>
        )}

        {booking.status === BookingStatus.BookingConfirmed &&
          booking.attendanceConfirmation &&
          isToday && (
            //today
            <SlideButton
              onSlide={() => onCheckin()}
              content={
                <div className="slider-content">
                  <span className="main">Check in</span>
                  <span>To be greeted by venue's staff</span>
                </div>
              }
            >
              <img
                src={CheckinCircle}
                alt=""
                draggable="false"
                style={{ height: "100%" }}
              />
            </SlideButton>
          )}

        {booking.status === BookingStatus.WaitingContent && isToday && (
          <Button
            type="primary"
            size="large"
            shape="round"
            style={{ width: "100%" }}
            onClick={() =>
              navigate("/certificate", {
                state: { booking },
              })
            }
          >
            Show voucher
          </Button>
        )}

        {booking.status === BookingStatus.WaitingContent ||
          (booking.status === BookingStatus.BookingConfirmed && isPast && (
            <Button
              size="large"
              type="primary"
              shape="round"
              onClick={() =>
                navigate("/book/submit-content", { state: booking })
              }
              style={{ flex: 1 }}
            >
              Submit content
            </Button>
          ))}
      </>
    );
  };

  return (
    <div style={{ padding: 20 }} className="book-detail collab-detail">
      <Row justify="space-between">
        <LeftOutlined
          style={{ cursor: "pointer" }}
          onClick={() => navigate(-1)}
        />
        {/* <span style={{ flex: 1, textAlign: "center" }}>⭐️4.5 {" ∘ "} $$$</span> */}
        {(booking.status === BookingStatus.PendingConfirmation ||
          booking.status === BookingStatus.BookingConfirmed) && (
          <Dropdown menu={{ items: items() as any }} trigger={["click"]}>
            <EllipsisOutlined className="bigger-svg" />
          </Dropdown>
        )}
      </Row>
      <div style={{ overflow: "auto" }}>
        <Typography.Title level={1} style={{ marginTop: 0 }}>
          {booking.venue.name}
        </Typography.Title>
        <Tag
          className={`tag ${colorTagByStatus(booking.status)}`}
          style={{ borderRadius: 8, marginBottom: 12 }}
        >
          {labelByStatys(booking.status, booking?.applicationDate?.date)}
        </Tag>
        {booking.applicationDate?.date && (
          <Row style={{ gap: 8 }}>
            <ClockCircleOutlined style={{ fontSize: 18 }} />
            {`${dayjs
              .utc(booking.applicationDate?.date)
              .format("dddd, MMM DD, ")} ${booking.applicationDate?.hour}`}
          </Row>
        )}
        <Row style={{ marginTop: 12, gap: 6 }}>
          <LocationIcon />
          <a
            href={getGoogleMapsUrl(booking.venue)}
            target="_blank"
            rel="noreferrer"
            className="ellipsis"
            style={{ width: 300 }}
          >
            {booking.venue.addressDisplay ?? booking.venue.address}
          </a>
        </Row>
        <Row style={{ gap: 8, marginTop: 12 }}>
          {booking.guestAllowed && (
            <div className="box-perk">
              🍴
              <div className="value">
                <span>Guests</span>
                <span>
                  <b>+1</b>
                </span>
              </div>
            </div>
          )}
          {booking.compensationFood > 0 && (
            <div className="box-perk">
              🍔
              <div className="value">
                <span>Food</span>
                <span>
                  <b>${booking.compensationFood}</b>
                </span>
              </div>
            </div>
          )}
          {booking.compensationCash > 0 && (
            <div className="box-perk">
              💸
              <div className="value">
                <span>Comp</span>
                <span>
                  <b>${booking.compensationCash}</b>
                </span>
              </div>
            </div>
          )}
        </Row>
        {/* <Row style={{ marginTop: 24 }}>
          <SlideButton
            onSlide={() => navigate(`/book/${booking.collabId}`)}
            content={
              <div className="slider-content" style={{}}>
                <span className="main">Book a visit</span>
                <span>{`only ${collab?.numberOfFoodiesRemaining} spots left`}</span>
              </div>
            }
          >
            <BookIcon />
          </SlideButton>
        </Row> */}
        <Row>
          <div className="briefing" style={{ margin: "20px 0" }}>
            <span className="title">Deliverables:</span>
            <ul>
              {booking?.deliverables?.map((item) => (
                <li className="circle checkmark">{item}</li>
              ))}
            </ul>
            {booking?.collabDetails?.promotionOfferSummary && (
              <>
                <span className="title">
                  Mention this offer to your followers:
                </span>
                <Typography.Paragraph style={{ display: "flex", gap: 6 }}>
                  {booking?.collabDetails?.promotionOfferSummary}
                </Typography.Paragraph>
              </>
            )}
            {booking?.collabDetails?.promotionActionDm &&
              booking?.instagramAccount && (
                <>
                  <span className="title">
                    To receive the offer they should DM @
                    {booking?.instagramAccount}:
                  </span>
                  <Typography.Paragraph
                    style={{ display: "flex", gap: 6 }}
                    onClick={() =>
                      onClickCopy(booking?.collabDetails?.promotionActionDm)
                    }
                  >
                    {booking?.collabDetails?.promotionActionDm}{" "}
                    <CopyIcon style={{ cursor: "pointer" }} />
                  </Typography.Paragraph>
                </>
              )}

            {booking?.collabDetails?.promotionSwipeLink && (
              <>
                <span className="title">
                  Add this swipe-up link to your story:
                </span>
                <Typography.Paragraph
                  style={{ display: "flex", gap: 6 }}
                  onClick={() =>
                    onClickCopy(booking?.collabDetails?.promotionSwipeLink)
                  }
                >
                  {booking?.collabDetails?.promotionSwipeLink}{" "}
                  <CopyIcon style={{ cursor: "pointer" }} />
                </Typography.Paragraph>
              </>
            )}
            {booking?.collabDetails?.promotionCaptionLink && (
              <>
                <span className="title">
                  Use this caption for the swipe-up:
                </span>
                <Typography.Paragraph
                  style={{ display: "flex", gap: 6 }}
                  onClick={() =>
                    onClickCopy(booking?.collabDetails?.promotionCaptionLink)
                  }
                >
                  {booking?.collabDetails?.promotionCaptionLink}{" "}
                  <CopyIcon style={{ cursor: "pointer" }} />
                </Typography.Paragraph>
              </>
            )}

            <span className="title">Instructions:</span>
            <Typography.Paragraph>
              {booking.collabDetails?.focusPoints}
            </Typography.Paragraph>
          </div>
        </Row>
        <Row
          style={{
            position: isMobile ? "fixed" : "inherit",
            width: isMobile ? "calc(100% - 24px)" : "100%",
            left: isMobile ? 12 : "50%",
            bottom: isMobile ? 100 : 0,
          }}
        >
          <Buttons />
        </Row>
      </div>
    </div>
  );
};

export default BookDetail;
