import { LeftOutlined } from "@ant-design/icons";
import { Button, Checkbox, Col, Input, Row, Typography } from "antd";
import { Collab } from "interfaces/collab";
import { UserProfile } from "interfaces/user-profile";
import { debounce } from "lodash";
import { useEffect, useState, useCallback, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { getLoggedUserInfo } from "redux-context/user";
import { updateUserInfoDietary } from "services/SauceService";
import { DietaryPreferences } from "types/dietary-preferences";

interface Props {
  // Define your component's props aqui
}

const getOtherText = (dietaryPreferences?: DietaryPreferences) => {
  return dietaryPreferences?.otherText || "";
};

const getDietaryRestrictionsRaw = (dietaryPreferences?: DietaryPreferences) => {
  if (!dietaryPreferences) return [];
  return Object.keys(dietaryPreferences).filter(
    (key) =>
      dietaryPreferences[key as keyof typeof dietaryPreferences] === true &&
      key !== "other" &&
      key !== "none"
  );
};

const transformRestrictionsIntoObject = (restrictions: string[]) => {
  const obj: DietaryPreferences = {
    none: false,
    other: false,
    vegetarian: false,
    vegan: false,
    lactoseIntolerance: false,
    glutenIntolerance: false,
    noPork: false,
    nutsAllergy: false,
    pescatarian: false,
    shellfishAllergy: false,
    halal: false,
    otherText: "",
  };

  restrictions.forEach((item) => {
    (obj as any)[item as keyof typeof obj] = true;
  });
  return obj;
};

const transformRestrictionsIntoList = (restrictions: DietaryPreferences) => {
  return getDietaryRestrictionsRaw(restrictions);
};

const DietaryRestrictions: React.FC<Props> = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch<any>();
  const { state } = location;
  const { page } = state || {};
  const { loggedUser }: { loggedUser: UserProfile } = useSelector(
    (state: any) => state.user
  );
  const [restrictions, setRestrictions] = useState<string[]>([]);
  const [otherSelected, setOtherSelected] = useState<boolean>(false);
  const [noneSelected, setNoneSelected] = useState<boolean>(false);
  const [otherValue, setOtherValue] = useState<string>("");

  const debouncedSave = useRef(
    debounce(async (preferences: DietaryPreferences) => {
      await updateUserInfoDietary({ dietaryPreferences: preferences });
      dispatch(getLoggedUserInfo());
    }, 3000)
  ).current;

  const onSave = useCallback(
    (params: {
      restriction?: string[];
      other?: boolean;
      none?: boolean;
      otherText?: string;
    }) => {
      const preferences: DietaryPreferences = {
        ...transformRestrictionsIntoObject(params.restriction ?? restrictions),
        other: params.other ?? otherSelected,
        none: params.none ?? noneSelected,
        otherText: params.otherText ?? otherValue,
      };
      debouncedSave(preferences);
    },
    [restrictions, otherSelected, noneSelected, otherValue, debouncedSave]
  );

  const onChangeValues = (values: string[]) => {
    setRestrictions(values);
    onSave({ restriction: values });
  };

  const onClickSave = () => {
    const { collab }: { collab: Collab } = state;
    if (collab.ghostKitchen) {
      navigate("/book/address-delivery", {
        state: { ...state },
      });
      return;
    }

    navigate("/book/collab-confirmation", {
      state: { ...state },
    });
  };

  const onChangeNone = (e: any) => {
    setNoneSelected(e.target.checked);
    setRestrictions([]);
    setOtherSelected(false);
    setOtherValue("");
    onSave({
      none: e.target.checked,
      restriction: [],
      other: false,
      otherText: "",
    });
  };

  const onChangeOther = (e: any) => {
    setOtherSelected(e.target.checked);
    onSave({
      other: e.target.checked,
    });
  };

  const onChangeOtherValue = (e: any) => {
    setOtherValue(e.target.value);
    onSave({
      otherText: e.target.value,
    });
  };

  useEffect(() => {
    if (loggedUser) {
      setRestrictions(
        transformRestrictionsIntoList(loggedUser?.dietaryPreferences)
      );
      setNoneSelected(loggedUser?.dietaryPreferences?.none || false);
      const otherText = getOtherText(loggedUser?.dietaryPreferences);
      setOtherValue(otherText || "");
      setOtherSelected(!!otherText);
    }
  }, [loggedUser]);

  return (
    <div className="page-full-screen">
      <div>
        <Row
          justify="space-between"
          style={{ alignItems: "center", marginTop: 20 }}
        >
          <div style={{ display: "flex" }}>
            <LeftOutlined
              style={{ cursor: "pointer" }}
              onClick={() => navigate(-1)}
            />
            <Typography.Title level={3} style={{ margin: "auto 20px" }}>
              Dietary restrictions
            </Typography.Title>
          </div>
        </Row>

        <Row>
          <span className="general-sub-title">
            Let us know if you have any dietary restrictions
          </span>
        </Row>

        <div style={{ textAlign: "center", marginTop: 16 }}>
          <Col span={24}>
            <div style={{ display: "flex", marginTop: 12 }}>
              <Checkbox
                checked={noneSelected}
                value="none"
                onChange={onChangeNone}
              >
                None
              </Checkbox>
            </div>
          </Col>
          <Checkbox.Group
            style={{ width: "100%" }}
            onChange={onChangeValues}
            value={restrictions}
          >
            <Row justify="start">
              <Col span={24}>
                <div style={{ display: "flex", marginTop: 12 }}>
                  <Checkbox disabled={noneSelected} value="vegan">
                    Vegan
                  </Checkbox>
                </div>
              </Col>
              <Col span={24}>
                <div style={{ display: "flex", marginTop: 12 }}>
                  <Checkbox disabled={noneSelected} value="vegetarian">
                    Vegetarian
                  </Checkbox>
                </div>
              </Col>
              <Col span={24}>
                <div style={{ display: "flex", marginTop: 12 }}>
                  <Checkbox disabled={noneSelected} value="lactoseIntolerance">
                    Lactose Intolerance
                  </Checkbox>
                </div>
              </Col>
              <Col span={24}>
                <div style={{ display: "flex", marginTop: 12 }}>
                  <Checkbox disabled={noneSelected} value="glutenIntolerance">
                    Gluten Intolerance
                  </Checkbox>
                </div>
              </Col>
              <Col span={24}>
                <div style={{ display: "flex", marginTop: 12 }}>
                  <Checkbox disabled={noneSelected} value="noPork">
                    No Pork
                  </Checkbox>
                </div>
              </Col>
              <Col span={24}>
                <div style={{ display: "flex", marginTop: 12 }}>
                  <Checkbox disabled={noneSelected} value="nutsAllergy">
                    Nuts Allergy
                  </Checkbox>
                </div>
              </Col>
              <Col span={24}>
                <div style={{ display: "flex", marginTop: 12 }}>
                  <Checkbox disabled={noneSelected} value="pescatarian">
                    Pescatarian
                  </Checkbox>
                </div>
              </Col>
              <Col span={24}>
                <div style={{ display: "flex", marginTop: 12 }}>
                  <Checkbox disabled={noneSelected} value="shellfishAllergy">
                    Shellfish Allergy
                  </Checkbox>
                </div>
              </Col>
              <Col span={24}>
                <div style={{ display: "flex", marginTop: 12 }}>
                  <Checkbox disabled={noneSelected} value="halal">
                    Halal
                  </Checkbox>
                </div>
              </Col>
            </Row>
          </Checkbox.Group>
          <Col span={24}>
            <div style={{ display: "flex", marginTop: 12 }}>
              <Checkbox
                checked={otherSelected}
                onChange={onChangeOther}
                disabled={noneSelected}
              >
                Other
              </Checkbox>
            </div>
          </Col>
          <Col span={24}>
            <div style={{ display: "flex", marginTop: 30 }}>
              <Input
                placeholder="Please specify"
                disabled={!otherSelected}
                onChange={onChangeOtherValue}
                value={otherValue}
              />
            </div>
          </Col>
        </div>
      </div>
      {page === "book" && (
        <Button type="primary" onClick={onClickSave}>
          Next
        </Button>
      )}
    </div>
  );
};

export default DietaryRestrictions;
