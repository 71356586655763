import { message } from "antd";
import { autenticado, TOKEN } from "helpers/auth-functions";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { signInInstagramWeb } from "services/SauceService";

const InstagramAuth: React.FC = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const handleAuth = async () => {
      const params = new URLSearchParams(window.location.search);
      const code = params.get("code");

      if (code) {
        try {
          const params = {
            code,
            internalAccessToken:
              (autenticado() && localStorage.getItem(TOKEN)) || undefined,
            web: true,
          };
          const loginInfo: any = await signInInstagramWeb(params);
          // Replace with your backend endpoint to exchange the code for an access token

          navigate("/signin-link?accessToken=" + loginInfo.accessToken);
        } catch (error) {
          console.error("Error during authentication:", error);
          message.error("Error during authentication");
          if (autenticado()) {
            navigate("/profile/social-media");
          } else {
            navigate("/login");
          }
        }
      } else {
        console.error("No code found in URL");
      }
    };

    handleAuth();
  }, [navigate]);

  return (
    <div>
      <h1>Instagram Authentication</h1>
      <p>Authenticating...</p>
    </div>
  );
};

export default InstagramAuth;
