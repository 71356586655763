import { logout, TOKEN } from "helpers/auth-functions";
import { UserProfile } from "interfaces/user-profile";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { getLoggedUserInfo } from "redux-context/user";
import LoadingFullScreen from "./LoadingFullScreen";

interface SigninAppProps {}

const SigninApp: React.FC<SigninAppProps> = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch<any>();
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get("accessToken");
  const { loggedUser, loading }: { loggedUser: UserProfile; loading: boolean } =
    useSelector((state: any) => state.user);

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (token) {
      localStorage.setItem(TOKEN, token);
      dispatch(getLoggedUserInfo());
      timeout = setTimeout(() => {
        navigate(".", { replace: true });
      }, 10000);
    } else {
      console.log("no token");
      logout();
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [token, dispatch, navigate]);

  useEffect(() => {
    if (!loading) {
      if (loggedUser._id) {
        navigate("/");
      } else {
        logout();
      }
    }
  }, [loggedUser, loading, navigate]);

  return <LoadingFullScreen />;
};

export default SigninApp;
