import { ClockCircleOutlined, LeftOutlined } from "@ant-design/icons";
import { Row, Typography } from "antd";
import dayjs from "dayjs";
import { useMemo } from "react";
import { useLoaderData, useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as LocationIcon } from "assets/images/location-icon.svg";
import { ReactComponent as BookIcon } from "assets/images/book-icon.svg";
import { SlideButton } from "components";
import "./Collabs.scss";
import { getGoogleMapsUrl } from "helpers/google-maps-utils";

const weekDays = [
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
  "sunday",
];

const CollabDetail: React.FC = () => {
  const { collab } = useLoaderData() as any;
  const navigate = useNavigate();
  const location = useLocation();

  const { state } = location;

  const daysOfWeek = useMemo(() => {
    return (
      collab?.bookingAvailability
        ?.filter((day: any) => day.open !== false)
        .map((day: any) => day?.dayOfWeek?.toLowerCase()) || []
    );
  }, [collab]);

  const openDay = (day: string) => {
    if (daysOfWeek.includes(day)) return "open";
    return "";
  };

  return (
    <div style={{ padding: 20 }} className="collab-detail">
      <Row>
        <LeftOutlined
          style={{ cursor: "pointer" }}
          onClick={() => navigate(-1)}
        />
        {/* <span style={{ flex: 1, textAlign: "center" }}>⭐️4.5 {" ∘ "} $$$</span> */}
      </Row>
      <div style={{ overflow: "auto" }}>
        <Typography.Title level={1} style={{ marginTop: 0 }}>
          {collab.venue.name}
        </Typography.Title>
        <Row style={{ gap: 8 }}>
          <ClockCircleOutlined style={{ fontSize: 18 }} /> {" from "}
          {dayjs.utc(collab.startDate).format("MMM DD")}
        </Row>
        <div className="week-list" style={{ marginLeft: 24, marginTop: 6 }}>
          {weekDays.map((day) => (
            <span key={day} className={`day ${openDay(day)}`}>
              {day[0].toUpperCase()}
            </span>
          ))}
        </div>
        <Row style={{ marginTop: 12, gap: 6 }}>
          <LocationIcon />
          <a
            href={getGoogleMapsUrl(collab.venue)}
            target="_blank"
            rel="noreferrer"
            className="ellipsis"
            style={{ width: 300 }}
          >
            {collab.venue.addressDisplay ?? collab.venue.address}
          </a>
        </Row>
        <Row style={{ gap: 8, marginTop: 12 }}>
          {collab.guestAllowed && (
            <div className="box-perk">
              🍴
              <div className="value">
                <span>Guests</span>
                <span>
                  <b>+1</b>
                </span>
              </div>
            </div>
          )}
          {collab.compensationFoodNumber > 0 && (
            <div className="box-perk">
              🍔
              <div className="value">
                <span>Food</span>
                <span>
                  <b>${collab.compensationFoodNumber}</b>
                </span>
              </div>
            </div>
          )}
          {collab.compensationCashNumber > 0 && (
            <div className="box-perk">
              💸
              <div className="value">
                <span>Comp</span>
                <span>
                  <b>${collab.compensationCashNumber}</b>
                </span>
              </div>
            </div>
          )}
        </Row>
        <Row style={{ marginTop: 24 }}>
          <SlideButton
            onSlide={() =>
              navigate(`/collab/book/${collab.collabId}`, { state })
            }
            content={
              <div className="slider-content" style={{}}>
                <span className="main">Request a visit</span>
                <span>{`only ${collab?.numberOfFoodiesRemaining} spots left`}</span>
              </div>
            }
          >
            <BookIcon />
          </SlideButton>
        </Row>
        <Row>
          <div className="briefing" style={{ marginBottom: 80 }}>
            <span className="title">Brief:</span>
            <Typography.Paragraph>{collab.brief}</Typography.Paragraph>

            <span className="title">Deliverables:</span>
            <ul>
              {collab?.deliverables?.instagramReel && (
                <li className="circle checkmark">Instagram Reel</li>
              )}
              {collab?.deliverables?.instagramStory && (
                <li className="circle checkmark">Instagram Story</li>
              )}
              {collab?.deliverables?.tiktokVideo && (
                <li className="circle checkmark">TikTok Video</li>
              )}
            </ul>
          </div>
        </Row>
      </div>
    </div>
  );
};

export default CollabDetail;
