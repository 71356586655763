import { ReactComponent as CopyIcon } from "assets/images/copy-icon.svg";
import { onClickCopy } from "helpers/copy-helper";
const DevTools: React.FC = () => {
  const localStorageItems = Object.entries(localStorage);

  return (
    <div style={{ margin: "0 20px" }}>
      <h1>Dev Tools</h1>
      <div>
        {localStorageItems.map(([key, value]) => (
          <div key={key} style={{ marginBottom: "10px" }}>
            <strong>{key}:</strong>
            <p style={{ overflow: "auto" }}>{value}</p>
            <CopyIcon onClick={() => onClickCopy(value)} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default DevTools;
