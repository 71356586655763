import { ThemeConfig } from "antd";

export const theme: ThemeConfig = {
  token: {
    colorPrimary: "#FFAC39",
    colorTextBase: "#1D2023",
  },
  components: {
    Layout: {
      siderBg: "#F9F4E9",
      bodyBg: "#F9F4E9",
      headerBg: "#F9F4E9",
      headerPadding: "0 16px",
    },
    Menu: {
      itemActiveBg: "#fff",
      itemSelectedBg: "#fff",
      itemBg: "#F9F4E9",
      itemSelectedColor: "#000",
      padding: 0,
      activeBarBorderWidth: 0,
    },
    Button: {
      primaryColor: "#1D2023",
      colorLink: "#1D2023",
      colorLinkHover: "#1D2023",
      paddingBlock: "10px",
      paddingInline: "20px",
      controlHeight: 44,
      fontWeight: 500,
      lineHeight: 20,
      borderRadius: 30,
      defaultBorderColor: "#1D2023",
      colorError: "#FF354A",
    },
    Typography: {
      fontSize: 16,
    },
    Form: {
      labelFontSize: 16,
    },
    Select: {
      controlHeight: 50,
      fontSize: 16,
    },
    Input: {
      controlHeight: 50,
      fontSize: 16,
      lineHeight: 1.5,
    },
    InputNumber: {
      controlHeight: 50,
      fontSize: 16,
      lineHeight: 1.5,
    },
    DatePicker: {
      controlHeight: 48,
      fontSize: 16,
      lineHeight: 1.5,
    },
    Switch: {
      controlHeight: 12,
      colorPrimary: "#1D2023",
      handleSize: 8,
      trackHeight: 12,
      trackMinWidth: 24,
      innerMaxMargin: 12,
    },
    Checkbox: {
      colorPrimary: "#1D2023",
      colorPrimaryHover: "#1D2023",
      fontSize: 16,
    },
    Slider: {
      colorPrimary: "#1D2023",
      trackBg: "#1D2023",
      dotBorderColor: "#1D2023",
      dotActiveBorderColor: "#1D2023",
      handleActiveColor: "#1D2023",
      handleColor: "#1D2023",
      colorBgElevated: "#1D2023",
      trackHoverBg: "#1D2023",
      colorPrimaryBorderHover: "#1D2023",
    },
    Tag: {
      fontSize: 12,
      borderRadiusSM: 20,
      colorBorder: "transparent",
      defaultBg: "#F5F5F5",
    },
    Segmented: {
      borderRadius: 32,
      borderRadiusSM: 32,
      boxShadowTertiary: "",
      trackBg: "#EDE8DD",
      controlHeightLG: 64,
      controlPaddingHorizontal: 20,
    },
    Avatar: {
      groupBorderColor: "#ffac39",
    },
    Calendar: {
      fullPanelBg: "transparent",
      fullBg: "transparent",
    },
  },
};
