import { Typography } from "antd";

const NotFound: React.FC = () => {
  return (
    <div
      style={{
        textAlign: "center",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <div style={{ textAlign: "center" }}>
        <Typography.Title level={3}>
          Sorry we couldn't find the page you are looking for...
        </Typography.Title>
      </div>
    </div>
  );
};

export default NotFound;
