import { useLocation, useNavigate } from "react-router-dom";
import { Row, Typography } from "antd";
import { ReactComponent as CollabsIcon } from "assets/images/collabs-icon.svg";
import { ReactComponent as CalendarIcon } from "assets/images/calendar-icon.svg";
import { ReactComponent as LocationIcon } from "assets/images/location-icon.svg";
import dayjs from "dayjs";
import { getGoogleMapsUrl } from "helpers/google-maps-utils";
import { Booking } from "interfaces/booking";
import { LeftOutlined } from "@ant-design/icons";
import "./Certificate.scss";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearBackgroundColor, setBackgroundColor } from "redux-context/page";
import { UserProfile } from "interfaces/user-profile";

interface CertificateProps {
  // Define the props for your component here
}

const Certificate: React.FC<CertificateProps> = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { state } = location || {};
  const { booking }: { booking: Booking } = state || {};
  const { loggedUser }: { loggedUser: UserProfile } = useSelector(
    (state: any) => state.user
  );

  useEffect(() => {
    dispatch(setBackgroundColor("#e2f2dc"));

    return () => {
      dispatch(clearBackgroundColor("#ffffff"));
    };
  }, [dispatch]);

  return (
    <div className="page-full-screen fixed green">
      <div className="certificate">
        <Row style={{ width: "100%" }}>
          <LeftOutlined
            style={{ cursor: "pointer" }}
            onClick={() => navigate("/")}
          />
        </Row>

        <Typography.Title
          level={1}
          style={{
            width: "100%",
            textAlign: "start",
          }}
        >
          Food Voucher
        </Typography.Title>
        <Typography.Paragraph>
          This voucher entitles the presenter with the perks listed below:
        </Typography.Paragraph>

        <div className="card">
          <CollabsIcon className="logo" />
          <div className="card-body">
            <div className="card-id">{booking.securityCode}</div>
            <div className="card-name">{loggedUser?.name}</div>
            <Row style={{ color: "#1D2023", gap: 4 }}>
              <CalendarIcon className="color-black" />
              <span>
                {dayjs.utc(booking.applicationDate.date).format("MMM DD")}{" "}
                {booking.applicationDate?.hour}
              </span>{" "}
            </Row>
            <Row style={{ marginTop: 12, gap: 6 }} justify="start">
              <LocationIcon />
              <a
                href={getGoogleMapsUrl(booking.venue)}
                target="_blank"
                rel="noreferrer"
                className="ellipsis"
                style={{ width: 280, textAlign: "start" }}
              >
                {booking.venue.addressDisplay ?? booking.venue.address}
              </a>
            </Row>
            <Row style={{ gap: 8, marginTop: 12 }}>
              {booking.guestAllowed && (
                <div className="box-perk">
                  <span className="emoji-size">🍴</span>
                  <div className="value">
                    <span>Guests</span>
                    <span>
                      <b>+1</b>
                    </span>
                  </div>
                </div>
              )}
              {booking.compensationFood > 0 && (
                <div className="box-perk">
                  <span className="emoji-size">🍔</span>
                  <div className="value">
                    <span>Food</span>
                    <span>
                      <b>${booking.compensationFood}</b>
                    </span>
                  </div>
                </div>
              )}
              {booking.compensationCash > 0 && (
                <div className="box-perk">
                  <span className="emoji-size">💸</span>
                  <div className="value">
                    <span>Comp</span>
                    <span>
                      <b>${booking.compensationCash}</b>
                    </span>
                  </div>
                </div>
              )}
            </Row>
            <div className="card-footer">
              <span className="gratuity">
                Gratuity is not included, please don't forget to tip the waiters
                that serve you.
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Certificate;
