import { Divider, Drawer } from "antd";
import { UserProfile } from "interfaces/user-profile";
import { useState } from "react";
import { isDesktop } from "react-device-detect";
import { useSelector } from "react-redux";
import "./Collabs.scss";

const DrawerRating: React.FC = () => {
  const [open, setOpen] = useState<boolean>(false);
  const { loggedUser }: { loggedUser: UserProfile } = useSelector(
    (state: any) => state.user
  );

  const colorByReputation = (defaultColor: string) =>
    loggedUser?.collabReputation <= 50 ? "#FF354A" : defaultColor;

  const labelByReputation = () => {
    if (loggedUser?.collabReputation) {
      if (loggedUser.collabReputation > 90) return "High rating";
      if (loggedUser.collabReputation > 80) return "Good rating";
      if (loggedUser.collabReputation > 50) return "Low rating";
      return "High rating";
    }
  };

  return (
    <>
      <div className="rating-box" onClick={() => setOpen(true)}>
        <span>My collabs</span>
        <span className="rating">⚡️ {loggedUser?.collabReputation}</span>
      </div>
      <Drawer
        placement="bottom"
        rootClassName="drawer-rating"
        title="Rating"
        onClose={() => setOpen(false)}
        open={open}
        // style={{ width: 400 }}
        classNames={{
          header: "collabs-drawer-header",
          body: "collabs-drawer-body",
        }}
        styles={{
          header: {
            border: "none",
            // width: 400,
          },
          // content: {
          //   alignItems: "center",
          // },
          body: {
            marginLeft: isDesktop ? 265 : 0,
            justifyContent: "center",
            display: "flex",
          },
        }}
      >
        <div>
          <span
            className="rating-percentage"
            style={{
              color: colorByReputation("#000"),
            }}
          >
            {loggedUser?.collabReputation}%
          </span>
          <span
            className="reputation-label"
            style={{
              color: colorByReputation("#009546"),
            }}
          >
            {" "}
            {labelByReputation()}
          </span>
          <span className="disclaimer">
            Maintain high rating to qualify for collaborations
          </span>
          <Divider />
          <h2 className="sub-title">Your acceptance</h2>
          <div className="content">
            <div className="box-detail green">
              <span className="value">
                {loggedUser.reputationDetails?.amountApplied}
              </span>
              <span className="label">Applied</span>
            </div>
            <div className="box-detail green">
              <span className="value">
                {loggedUser.reputationDetails?.amountCompleted}
              </span>
              <span className="label">Completed</span>
            </div>
            <div className="box-detail red">
              <span className="value">
                {loggedUser.reputationDetails?.amountCanceled}
              </span>
              <span className="label">Canceled</span>
            </div>
            <div className="box-detail red">
              <span className="value">
                {loggedUser.reputationDetails?.amountRescheduled}
              </span>
              <span className="label">Rescheduled</span>
            </div>
          </div>
        </div>
      </Drawer>
    </>
  );
};

export default DrawerRating;
