import axios, { AxiosResponse, InternalAxiosRequestConfig } from "axios";
import { logout, TOKEN } from "helpers/auth-functions";
import * as Sentry from "@sentry/react";
// import { logout } from "helpers/auth-functions";

export const instance = axios.create({
  baseURL: "https://api.sauce.mustard.love/api/",
});

instance.interceptors.request.use((config: InternalAxiosRequestConfig) => {
  const token = localStorage.getItem(TOKEN);
  if (token) {
    config.headers["Authorization"] = `Bearer ${token}`;
  } else {
    config.headers["x-api-key"] = `OPKPPQzf4U2spiV3lgiBhfvXzNAJSAvdfs`;
  }
  return config;
});

instance.interceptors.response.use(
  (response: AxiosResponse<any>) => {
    return response.data;
  },
  (error: any) => {
    if (axios.isCancel(error)) {
      console.log("Requisição cancelada:", error.message);
    } else if (error) {
      console.log(error);
      switch (error?.response?.status) {
        case 401:
        case 403:
          logout();
          break;
        default:
          Sentry.withScope((scope) => {
            const errorDetails = {
              message: "Error caught in Sauce service",
            };

            scope.setExtras(errorDetails);
            Sentry.captureException(error);
          });
          break;
      }
    }
    return Promise.reject(error);
  }
);

export const signInMeta = (data: any) => {
  return instance.post("auth/sign-in-meta", data);
};

export const signInInstagramWeb = (data: any) => {
  return instance.post("auth/sign-in-instagram", data);
};

export const signInTiktok = (data: any) => {
  return instance.post("auth/sign-in-tiktok", data);
};

export const signInPhone = (data: any) => {
  return instance.post("auth/sign-in-phone", data);
};

export const retrieveUserIgAccounts = () => {
  return instance.post("auth/retrieve-user-ig-accounts");
};

export const assignIgAccountUser = (data: any) => {
  return instance.post("auth/assign-ig-account-user", data);
};

export const getAvailableCollabsCities = () => {
  return instance.post("collabs/available-collabs-cities");
};

export const getAvailableCollabsByFilter = (params: any) => {
  return instance.post("collabs/available-collabs-by-filter", params);
};

export const getCollabById = (id: string) => {
  return instance.get(`collabs/business/${id}`);
};

export const retrieveClosestLocation = (params: any) => {
  return instance.post("collabs/retrieve-closest-location", params);
};

export const getPlacesByName = (params: any) => {
  return instance.post(`maps/search-places-by-name`, params);
};

export const searchByZipcode = (params: any) => {
  return instance.post(`maps/search-by-zipcode`, params);
};

export const getUserInbox = (params: any) => {
  return instance.post(`bookings/user-inbox`, params);
};

export const getUserHistory = (params: any) => {
  return instance.post(`bookings/user-history`, params);
};

export const confirmBooking = (params: any) => {
  return instance.post(`bookings/confirm`, params);
};

export const getBookingById = (id: string) => {
  return instance.get(`bookings/by-id/${id}`);
};

export const cancelBooking = (params: any) => {
  return instance.post(`bookings/cancel`, params);
};

export const newBookingRequest = (data: any) => {
  return instance.post(`bookings/new-booking-request`, data);
};

export const getProfile = () => {
  return instance.get(`user/get-by-internal-id/66747f98a3aa090e7e06af6b`);
};

export const getFoodieCategories = () => {
  return instance.get(`info/foodie-categories`);
};

export const generateMagicLink = (email: string) => {
  return instance.post(`auth/generate-magic-link`, { email });
};

export const signInMagicLink = (accessToken: string) => {
  return instance.post(`auth/signin-magic-link`, { accessToken });
};

export const getUserLoggedInfo = () => {
  return instance.get(`profile/logged-user-info`);
};

export const updateUserInfoGeneral = (data: any) => {
  return instance.post(`profile/update-user-info-general`, data);
};

export const updateUserInfoCategory = (data: any) => {
  return instance.post(`profile/update-user-info-category`, data);
};

export const updateUserInfoDietary = (data: any) => {
  return instance.post(`profile/update-user-info-dietary`, data);
};

export const updateUserInfoNotification = (data: any) => {
  return instance.post(`profile/update-user-info-notification`, data);
};

export const verifyStripeStatus = (data: any) => {
  return instance.post(`profile/verify-stripe-status`, data);
};

export const createStripeLoggedUser = () => {
  return instance.post(`profile/create-stripe-wallet`);
};

export const retrieveStripeWallet = (data: any) => {
  return instance.post(`profile/retrieve-stripe-wallet`, data);
};

export const updateUserInfoLocation = (data: any) => {
  return instance.post(`profile/update-user-info-location`, data);
};

export const stripeCreatePayout = () => {
  return instance.post(`profile/stripe-create-payout`);
};

export const removeLoggedUserAccount = () => {
  return instance.post(`profile/remove-logged-user-account`);
};

export const checkIn = (data: any) => {
  return instance.post(`bookings/check-in`, data);
};

export const submitContent = (data: any) => {
  return instance.post(`bookings/submit-content`, data);
};

export const uploadLogo = (data: any) => {
  return instance.post(`profile/upload-logo`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const getStripeBalance = () => {
  return instance.post(`profile/stripe-balance`);
};

export const getStripeStatements = () => {
  return instance.post(`profile/stripe-statements`);
};
