import { Button, Typography } from "antd";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import contentSubmitted from "assets/images/content-submitted.png";
import { useDispatch } from "react-redux";
import { setBackgroundColor } from "redux-context/page";
import { autenticado } from "helpers/auth-functions";
import { Booking } from "interfaces/booking";

const ContentSumitted: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { state } = location;

  const booking = state as Booking;

  useEffect(() => {
    dispatch(setBackgroundColor("#e2f2dc"));
    return () => {
      dispatch(setBackgroundColor());
    };
  }, [dispatch]);

  return (
    <div className="page-full-screen fixed green">
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
          gap: 60,
        }}
      >
        <div>
          <Typography.Title level={3}>Amazing job!</Typography.Title>
          <Typography.Text>
            Thank you for working with {booking?.venue?.name}.Once we verify,
            we'll send the payment.
          </Typography.Text>
        </div>
        <img src={contentSubmitted} alt="" style={{ width: 220 }} />
      </div>
      {autenticado() && (
        <div>
          <Button
            type="primary"
            size="large"
            shape="round"
            onClick={() => navigate("/")}
            style={{ marginTop: 24, width: "100%" }}
          >
            Done
          </Button>
        </div>
      )}
    </div>
  );
};

export default ContentSumitted;
