import { TOKEN } from "helpers/auth-functions";
import { UserProfile } from "interfaces/user-profile";
import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { getLoggedUserInfo } from "redux-context/user";
import { signInMagicLink } from "services/SauceService";

interface MagicLinkProps {
  // Define the props for your component here
}

const MagicLink: React.FC<MagicLinkProps> = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch<any>();
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get("accessToken");
  const { loggedUser }: { loggedUser: UserProfile } = useSelector(
    (state: any) => state.user
  );

  const getPermanentToken = useCallback(
    async (tempToken: string) => {
      const response: any = await signInMagicLink(tempToken);

      if (response?.accessToken) {
        localStorage.setItem(TOKEN, response.accessToken);

        dispatch(getLoggedUserInfo());
      } else {
        console.log("this is not a valid token");
      }
    },
    [dispatch]
  );

  useEffect(() => {
    if (token) getPermanentToken(token);
    // if (token) {
    //   if (window.matchMedia("(display-mode: standalone)").matches) {
    //     // PWA está instalado, fazer login com magic link
    //     getPermanentToken(token);
    //   } else if (
    //     isMobile &&
    //     !window.matchMedia("(display-mode: standalone)").matches
    //   ) {
    //     // Redirecionar para o PWA instalado na homescreen em dispositivos móveis
    //     window.location.href = `https://app-collabs.mustard.love?sigin-link=${token}`;
    //   } else {
    //     // Prossiga com o login no navegador de desktop
    //     getPermanentToken(token);
    //   }
    // }
  }, [token, getPermanentToken]);

  useEffect(() => {
    if (loggedUser._id) {
      navigate("/");
    }
  }, [loggedUser, navigate]);
  return null;
};

export default MagicLink;
