import { LeftOutlined } from "@ant-design/icons";
import { Typography } from "antd";
import { useNavigate } from "react-router-dom";
import EmptyItems from "assets/images/empty-itens.png";

const Notification: React.FC = () => {
  const navigate = useNavigate();
  return (
    <div className="notifications">
      <div className="header">
        <LeftOutlined onClick={() => navigate("/")} />{" "}
        <Typography.Title level={3}>Notification</Typography.Title>
      </div>
      <div className="content">
        <img src={EmptyItems} alt="" />
        <span className="title">We'll notify about the important</span>
        <span className="sub-title">Here will be all you notifications</span>
      </div>
    </div>
  );
};

export default Notification;
