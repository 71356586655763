import { LeftOutlined } from "@ant-design/icons";
import { Button, Row, Typography } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import CollabFailedAvailability from "assets/images/collab-failed-availability.png";
import CollabFailedRejected from "assets/images/collab-failed-rejected.png";
import "./BookCollab.scss";

const BookCollabFailed: React.FC = () => {
  const navigate = useNavigate();
  const reason = false ? "not-available" : "not-approved";
  const location = useLocation();
  const { state } = location;

  const title =
    reason === "not-available"
      ? "No longer available"
      : "Sorry, we're stuffed!";

  const text =
    reason === "not-available"
      ? "Sorry, the collab is no longer available. No worries, we'll come up with something even better soon!"
      : "We filled up all the spots for this collab. Stay tuned for more collab opportunities!";

  const image =
    reason === "not-available"
      ? CollabFailedAvailability
      : CollabFailedRejected;

  return (
    <div className="book-confirmation red">
      <div>
        <Row
          justify="space-between"
          style={{ alignItems: "center", marginTop: 20 }}
        >
          <LeftOutlined
            style={{ cursor: "pointer" }}
            onClick={() => navigate(`/collab/book/${state?.collab?.collabId}`)}
          />
        </Row>
        <Typography.Title level={3} style={{ margin: "36px 0 16px 0" }}>
          {title}
        </Typography.Title>

        <Typography.Text style={{ margin: 0 }}>{text}</Typography.Text>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <img src={image} alt="collab" />
        </div>
      </div>
      <div className="buttons">
        <Button type="primary" onClick={() => navigate("/find-collabs")}>
          Back to Collabs
        </Button>
      </div>
    </div>
  );
};

export default BookCollabFailed;
